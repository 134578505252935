import { async } from '@angular/core/testing';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private http: HttpClient) {
  }
  ip: any;
  geolocationPosition: Position;
  latitude: any;
  longitude: any;
  browser: any;
  location: string = "";
  createUUID() {
    // var iDevices = [
    //   'iPad Simulator',
    //   'iPhone Simulator',
    //   'iPod Simulator',
    //   'iPad',
    //   'iPhone',
    //   'iPod'
    // ];

    // if (navigator.platform) {
    //   while (iDevices.length) {
    //     if (navigator.platform === iDevices.pop()) {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  //     }
  //   }
  //   return null;
  // }

  getDeviceType = () => {
    var platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    let os = null;
    const ua = navigator.userAgent;
    if (/(ipad)/i.test(ua)) {
      return "iPAD";
    }
    if (
      /iPhone/.test(ua)
    ) {
      return "iPhone";
    }
    if (
      /Mobile|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return "Mobile";
    }
    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'Mac OS';
    }
    if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows Desktop';
    }
    if (!os && /Linux/.test(platform)) {
      return 'Linux';
    }
  };

  getLocation() {
    if (window.navigator && window.navigator.geolocation) {
      navigator.geolocation.watchPosition(response => {
        this.latitude = response.coords.latitude;
        this.longitude = response.coords.longitude;
        this.location = this.latitude + "," + this.longitude;
      },
      );
    };
  }

  getBrowserName() {
    var ua = navigator.userAgent, tem,
      M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE ' + (tem[1] || '');
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
      if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera').replace('Edg ', 'Edge ');
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  ngOnInit() {
    this.getLocation();
    this.http.get('https://api.kenmarkserver.com/getClientIP.php').toPromise().then(response => {
      var json = response;
      this.ip = json;
      let user =
      {
        UUID: this.createUUID(),
        IPv4: this.ip.ipAddress,
        IPv6: this.ip.ipAddress,
        deviceType: this.getDeviceType(),
        browserModel: this.getBrowserName(),
        geoLocation: this.location,
        deviceModel: navigator.appVersion,
      }
      //console.log(user);
      //this.http.post('https://kits.api-central.kenmarkserver.com/user/guest', user).subscribe();
    })
  }
}