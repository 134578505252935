import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs/internal/observable/throwError';
export class DataService {
  constructor(private url: string, private http: HttpClient) {
  }
  posts: any;
  getAll() {
    return this.http.get(this.url)
      .pipe(map(res => this.posts = res),
        catchError(this.errorHandler));
  }
  postAll(resource) {
    return this.http.post<any>(this.url, resource)
      .pipe(map(res => this.posts = res),
        catchError(this.errorHandler));
  }

  delete() { }

  errorHandler(error) {
    let errorMessage = '';
    if (error.code == 500) {
      errorMessage = 'Server is offline'
    }
    else if (navigator.onLine == false) {
      errorMessage = "You're not connected to the internet"
    }
    // if (error.error instanceof ErrorEvent) {
    //   errorMessage = error.error.message;
    // }
    else {
      errorMessage = error.error.message;
    }
    return throwError(errorMessage);
  }
}
