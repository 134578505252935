import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss']
})
export class DevelopmentComponent implements OnInit {
  thankYou: boolean;
  progressBar: number = 16.7;
  showProgress: boolean;
  devType: any = [];
  targetDevice: any = [];
  funcType: any[];
  name:any;
  email:any;
  mobile:any;
  companyName:any;
  message:any;

  devMenus = {
    erp: false,
    eCommerce: false,
    portfolio: false,
    landingPage: false,
    security: false,
    socialMediaPlatform: false,
    accounting: false,
    customBuild: false,
  }
  targetDeviceMobile = {
    mobile: false,
    browser: false,
    android: false,
    ios: false,
  }

  targetDeviceDesktop = {
    desktop: false,
    browser: false,
  }

  targetDeviceTablet = {
    tablet: false,
    browser: false,
    android: false,
    ios: false,
  }

  funcMenus = {
    paymentGateway: false,
    smsGateway: false,
    emailGateway: false,
    shippingGateway: false,
    otpVerification: false,
    identityVerificationKYC: false,
    googleMaps: false,
    googleAdWords: false,
    googleAnalytics: false,
    socialMediaIntegration: false,
    logInWithGoogle: false,
    logInWithFacebook: false,
    contactForm: false,
    blog: false,
    mailingListSubscription: false
  }
  constructor() { }

  ngOnInit(): void {
  }

  getStarted(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "flex";
    document.getElementById("firstStep1").style.display = "flex";
    document.getElementById("secondStep").style.display = "none";
    document.getElementById("secondStep1").style.display = "none";
    this.thankYou = false;
    if (type == 'start') {
      this.showProgress = true;
    } else if (type == 'end') {
      this.progressBar = 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }

  stepSecond(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "none";
    document.getElementById("firstStep1").style.display = "none";
    document.getElementById("secondStep").style.display = "flex";
    document.getElementById("secondStep1").style.display = "flex";
    document.getElementById("thirdStep").style.display = "none";
    document.getElementById("thirdStep1").style.display = "none";
    if (type == 'forward') {
      this.progressBar = this.progressBar + 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }

  stepThird(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "none";
    document.getElementById("firstStep1").style.display = "none";
    document.getElementById("secondStep").style.display = "none";
    document.getElementById("secondStep1").style.display = "none";
    document.getElementById("thirdStep").style.display = "flex";
    document.getElementById("thirdStep1").style.display = "flex";
    document.getElementById("fourthStep").style.display = "none";
    document.getElementById("fourthStep1").style.display = "none";
    if (type == 'forward') {
      this.progressBar = this.progressBar + 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }

  stepFourth(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "none";
    document.getElementById("firstStep1").style.display = "none";
    document.getElementById("secondStep").style.display = "none";
    document.getElementById("secondStep1").style.display = "none";
    document.getElementById("thirdStep").style.display = "none";
    document.getElementById("thirdStep1").style.display = "none";
    document.getElementById("fourthStep").style.display = "flex";
    document.getElementById("fourthStep1").style.display = "flex";
    document.getElementById("review").style.display = "none";
    document.getElementById("review1").style.display = "none";
    if (type == 'forward') {
      this.progressBar = this.progressBar + 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }

  submitReview(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "none";
    document.getElementById("firstStep1").style.display = "none";
    document.getElementById("secondStep").style.display = "none";
    document.getElementById("secondStep1").style.display = "none";
    document.getElementById("thirdStep").style.display = "none";
    document.getElementById("thirdStep1").style.display = "none";
    document.getElementById("fourthStep").style.display = "none";
    document.getElementById("fourthStep1").style.display = "none";
    document.getElementById("review").style.display = "flex";
    document.getElementById("review1").style.display = "flex";
    if (type == 'forward') {
      this.progressBar = this.progressBar + 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }
  thankYouPage(type) {
    document.getElementById("landingPage").style.display = "none";
    document.getElementById("firstStep").style.display = "none";
    document.getElementById("firstStep1").style.display = "none";
    document.getElementById("secondStep").style.display = "none";
    document.getElementById("secondStep1").style.display = "none";
    document.getElementById("thirdStep").style.display = "none";
    document.getElementById("thirdStep1").style.display = "none";
    document.getElementById("fourthStep").style.display = "none";
    document.getElementById("fourthStep1").style.display = "none";
    document.getElementById("review").style.display = "none";
    document.getElementById("review1").style.display = "none";
    this.thankYou = true;
    if (type == 'forward') {
      this.progressBar = this.progressBar + 16.7;
    } else if (type == 'backward') {
      this.progressBar = this.progressBar - 16.7;
    }
  }

  showThisStep(type) {
    if (type == 'Step 1') {
      document.getElementById("firstStep").style.display = "block";
      document.getElementById("firstStep1").style.display = "flex";
      document.getElementById("review").style.display = "none";
      document.getElementById("review1").style.display = "none";
      this.progressBar = 16.7;
    } else if (type == 'Step 2') {
      document.getElementById("secondStep").style.display = "block";
      document.getElementById("secondStep1").style.display = "flex";
      document.getElementById("review").style.display = "none";
      document.getElementById("review1").style.display = "none";
      this.progressBar = 33.4;
    } else if (type == 'Step 3') {
      document.getElementById("thirdStep").style.display = "block";
      document.getElementById("thirdStep1").style.display = "flex";
      document.getElementById("review").style.display = "none";
      document.getElementById("review1").style.display = "none";
      this.progressBar = 50.1;
    } else if (type == 'Step 4') {
      document.getElementById("fourthStep").style.display = "block";
      document.getElementById("fourthStep1").style.display = "flex";
      document.getElementById("review").style.display = "none";
      document.getElementById("review1").style.display = "none";
      this.progressBar = 66.8;
    }
  }


  getDevType(selectedMenu) {
    this.devMenus[selectedMenu] = !this.devMenus[selectedMenu];
    let selected = [];
    let keys = Object.keys(this.devMenus);
    keys.forEach(ele => {
      if (this.devMenus[ele]) {
        selected.push(ele)
      }
    });
    this.devType = selected;
    //console.log(this.devType);
  }

  getFuncType(selectedMenu) {
    this.funcMenus[selectedMenu] = !this.funcMenus[selectedMenu];
    let selected = [];
    let keys = Object.keys(this.funcMenus);
    keys.forEach(ele => {
      if (this.funcMenus[ele]) {
        selected.push(ele)
      }
    });
    this.funcType = selected;
    //console.log(this.funcType);
  }

  getTargetDevice(data) {
    if (data == 'mobile') {
      this.targetDeviceMobile[data] = !this.targetDeviceMobile[data];
      if (this.targetDeviceMobile[data] == true) {
        this.targetDeviceMobile.android = true;
        this.targetDeviceMobile.browser = true;
        this.targetDeviceMobile.ios = true;
        console.log(this.targetDeviceMobile);
      } else {
        this.targetDeviceMobile.android = false;
        this.targetDeviceMobile.browser = false;
        this.targetDeviceMobile.ios = false;
        console.log(this.targetDeviceMobile);
      }
    } else if (data == 'desktop') {
      this.targetDeviceDesktop[data] = !this.targetDeviceDesktop[data];
      if (this.targetDeviceDesktop[data] == true) {
        this.targetDeviceDesktop.browser = true;
        console.log(this.targetDeviceDesktop);
      } else {
        this.targetDeviceDesktop.browser = false;
        console.log(this.targetDeviceDesktop);
      }
    } else {
      this.targetDeviceTablet[data] = !this.targetDeviceTablet[data];
      if (this.targetDeviceTablet[data] == true) {
        this.targetDeviceTablet.browser = true;
        this.targetDeviceTablet.android = true;
        this.targetDeviceTablet.ios = true;
        console.log(this.targetDeviceTablet);
      } else {
        this.targetDeviceTablet.browser = false;
        this.targetDeviceTablet.android = false;
        this.targetDeviceTablet.ios = false;
        console.log(this.targetDeviceTablet);
      }
    }
  }

  getTargetMobilePlatform(data) {
    this.targetDeviceMobile[data] = !this.targetDeviceMobile[data];
    if(this.targetDeviceMobile[data] == true){
      this.targetDeviceMobile.mobile = true;
    }else {
      this.targetDeviceMobile.mobile = false;
    }
    console.log(this.targetDeviceMobile);
  }

  getTargetDesktopPlatform(data) {
    this.targetDeviceDesktop[data] = !this.targetDeviceDesktop[data];
    if(this.targetDeviceDesktop[data] == true){
      this.targetDeviceDesktop.desktop = true;
    }else {
      this.targetDeviceDesktop.desktop = false;
    }
    console.log(this.targetDeviceDesktop);
  }

  getTargetTabletPlatform(data) {
    this.targetDeviceTablet[data] = !this.targetDeviceTablet[data];
    if(this.targetDeviceTablet[data] == true){
      this.targetDeviceTablet.tablet = true;
    }else {
      this.targetDeviceTablet.tablet = false;
    }
    console.log(this.targetDeviceTablet);
  }
}
