import { Component, OnInit } from '@angular/core';
import { HostingService } from 'src/app/services/hosting.service';

@Component({
  selector: 'app-vps',
  templateUrl: './vps.component.html',
  styleUrls: ['./vps.component.scss']
})
export class VpsComponent implements OnInit {
  posts: any;
  type: string = "1"; //change service route of hosting service
  message: any;
  constructor(private service: HostingService) { }
  
  ngOnInit(): void {
    this.service.getAll(this.type)
      .subscribe(post => this.posts = post)
    this.service.currentPack.subscribe(message => this.message = message)
  }
  sendPack(post) {
    this.service.sendData(post)
  }
}
