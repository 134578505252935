import { Component, OnInit } from '@angular/core';
// import { AboutService } from 'src/app/services/about.service';

// declare var Swiper: any;
@Component({
  selector: 'app-our-team',
  templateUrl: './our-team.component.html',
  styleUrls: ['./our-team.component.scss']
})
export class OurTeamComponent implements OnInit {
  // posts:any;
  constructor() {    
  }
  ngOnInit() {
    // var swiper = new Swiper('.swiper-container', {
    //   slidesPerView: 4,
    //   breakpoints: {   
    //     320: {
    //       slidesPerView: 2,
    //       spaceBetween: 2,
    //     },    
    //     640: {
    //       slidesPerView: 2,
    //       spaceBetween: 2,
    //     },
    //     768: {
    //       slidesPerView: 3,
    //       spaceBetween: 2,
    //     },
    //     1024: {
    //       slidesPerView: 4,
    //       spaceBetween: 2,
    //     },
    //     1920: {
    //       slidesPerView: 4,
    //       spaceBetween: 2,
    //     },
    //     2560: {
    //       slidesPerView: 4,
    //       spaceBetween: 2,
    //     },
    //   },
    //   observer: true,
    //   observeParents: true,
    //   navigation: {
    //     nextEl: '.swiper-button-next',
    //     prevEl: '.swiper-button-prev',
    //   },
    // });

    // var swiper = new Swiper('.swiper-container-founder', {
    //   slidesPerView: 1,
    //   observer: true,
    //   observeParents: true,
    //   navigation: {
    //     nextE2: '.swiper-button-next1',
    //     prevE2: '.swiper-button-prev1',
    //   },
    // });
    // this.getAbout();
  }

  // getAbout(){
  //   this.service.getAll().subscribe(post => {
  //     console.log(post);
  //     this.posts = post;
  //   });
  // }
}
