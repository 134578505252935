import { ClientsService } from './../../services/clients.service';
import { Component, OnInit, AfterViewInit, QueryList, ViewChildren } from '@angular/core';


declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],

})
export class HomeComponent implements OnInit, AfterViewInit {

  @ViewChildren('fullpageRef') sectionRef: QueryList<any>;

  config: any;
  fullpage_api: any;
  constructor(private service: ClientsService) {
      this.config = {
      };
  }
  posts: any;
  ngOnInit() {
    // if (window.matchMedia("(min-width: 992px)").matches){
    //   var myFullpage = new fullpage('#fullpage',{
    //     // responsiveHeight: 992,
    //   });
    // }

    this.service.getAll()
      .subscribe(post => this.posts = post)
  }

  ngAfterViewInit() {
    this.sectionRef.changes.subscribe(() => {
      this.ngForRendred();
    });
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }


  ngForRendred() {
    console.log('ngFor dom render is done=>  ');
    if (!window.matchMedia("(min-width: 993px)").matches) {
      let a = document.querySelector("div[fullpage]");
      console.log(a.setAttribute('id', 'notfullpage'));
    }

    this.fullpage_api.build();
  }

}
