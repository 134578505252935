import { Component, OnInit } from '@angular/core';
import { HostingCustomService } from 'src/app/services/hosting-custom.service';
import { HostingService } from 'src/app/services/hosting.service';

@Component({
  selector: 'app-cloud-custom',
  templateUrl: './cloud-custom.component.html',
  styleUrls: ['./cloud-custom.component.scss']
})
export class CloudCustomComponent implements OnInit {
  backup = 0;
  os = 0;
  ssl = 0;
  cPanel = 0;
  ip = 0;
  type: any;
  posts: any;
  ipName: any;
  sslName: any;
  osName: any;
  backupName: any;
  cPanelName: any;
  pack: any;
  constructor(private service: HostingCustomService, private sharing: HostingService) { }
  ngOnInit() {
    this.service.getAll("1").subscribe(post => this.posts = post);
    this.sharing.currentPack.subscribe(message => this.pack = message);
  }
  osCost(cost, name) {
    this.os = cost;
    this.osName = name;
  }
  ipCost(cost, name) {
    this.ip = cost;
    this.ipName = name;
  }
  sslCost(cost, name) {
    this.ssl = cost;
    this.sslName = name;
  }
  backupCost(cost, name) {
    this.backup = cost;
    this.backupName = name;
  }
  cPanelCost(cost, name) {
    this.cPanel = cost;
    this.cPanelName = name;
  }

}
