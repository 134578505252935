<div class="container-fluid">
    <div class="row text-center p-lg-5" style="background-image: url('../../../assets/images/Background.png');">
      <div class="col p-lg-5">
        <p class="heading">Contact us</p>
      </div>
    </div>
    <div class="row text-center">
      <div class="col contactService">
        <div class="alwaysVisible">
          <img src="assets/images/icons/Group 134.png" alt="Call">
          <p class="textPara">Call</p>
        </div>
        <div class="onlyHover">
          <p class="textParaHover">Call</p>
          <a class="infoHover" href="tel:+91-9820283097">+91 9820283097</a>
        </div>
      </div>

      <div class="col contactService">
        <div class="alwaysVisible">
          <img src="assets/images/icons/Group 132.png" alt="Mail">
          <p class="textPara">Email</p>
        </div>
        <div class="onlyHover">
          <p class="textParaHover">Email</p>
          <a class="infoHover" href="mailto:info@kenmark.in">tan@kenmark.in</a>
        </div>
      </div>

      <div class="col contactService">
        <div class="alwaysVisible">
          <img src="assets/images/icons/Group 141.png" alt="Follow">
          <p class="textPara">Follow</p>
        </div>
        <div class="onlyHover">
          <p class="textParaHover">Follow</p>
          <a class="infoHover" href="https://instagram.com/kenmarkitansolutions">@kenmarkitansolutions</a>
          <p class="pt-3">
            <a style="padding: 0px 10px;"><img src="assets/images/icons/Twitter – 6.png" alt="Twitter"></a>
            <a style="padding: 0px 10px;"><img src="assets/images/icons/Facebook – 6.png" alt="Facebook"></a>
            <a style="padding: 0px 10px;"><img src="assets/images/icons/Instagram – 6.png" alt="Instagram"></a>
          </p>
        </div>
      </div>
    </div>
  </div>