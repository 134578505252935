<div class="container-fluid parentDiv">
	<div class="row">
		<div class="col-lg-3 col-sm-12 col-12 text-lg-left text-center pt-5 py-auto pl-lg-5 pack-details">
			<div class="sticky pl-lg-2 pt-lg-2">
				<p>{{((pack.priceINR)+os+memory+network+storageA+storageB+storageC+storageD+graphics| currency:'INR')}}/Mo
				</p>
				<p>{{pack.baseOS}}</p>
				<p>{{pack.baseControlPanel}}</p>
				<p>{{pack.maxMemory}}</p>
				<p>{{pack.memory}}</p>
				<p>{{pack.baseStorage}}</p>
				<p>{{pack.network}}</p>
				<p>{{pack.processor}}</p>
				<div class="package-cost-custom pb-5 pt-5">
					<abbr>{{pack.name}}</abbr>
					<p>Starting at {{pack.priceINR|currency:'INR'}}/Mo</p>
				</div>
			</div>
		</div>
		<div *ngIf='posts' class="p-lg-5 col-lg-9 col-sm-12 custom-detail">
			<div class="p-lg-5 p-sm-4 text-lg-left text-center">
				<div>
					<div class="section-head-small mt-4">Customize your Dedicated</div>
					<p>Bare-Metal Servers with a standard base system. They all come with unRAlD 6, which hosts a
						Software
						RAID Array, Docker Containers, Virtual Machines and Apps.
					</p>
					<hr class="dark mt-lg-5">
					<!--operating system custom-->
					<h4 class="py-4 font-weight-bold">Operating Systems</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Operating System</div>
						<div class="col">Access Type</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Operating System'"
							(click)="osCost(post.optionPricePerMo,post.id)">
							<div [ngClass]="osId==post.id? 'active-pack':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.optionName}}</div>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo>0 else zero"
										class="col-lg col-4 col-md-4 col-sm-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
								<ng-template #zero>
									<div *ngIf="(post.id==28 && osId==28) else free"
										class="col-lg col-4 col-md-4 col-sm-4 p-4">
										Included</div>
									<ng-template #free>
										<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
									</ng-template>
								</ng-template>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">Functional Operating Systems can be installed as Virtual
						Machines in unRAlD for everyday use.
					</div>
					<!--Mmeory custom-->
					<hr class="dark">
					<h4 class="py-4 font-weight-bold p-2">Memory</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Capacity</div>
						<div class="col">Bandwidth</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Memory'" (click)="memoryCost(post.optionPricePerMo,post.id)">
							<div [ngClass]="memoryId==post.id? 'active-pack':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.optionName}}</div>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo>0 else zero"
										class="col-lg col-4 col-sm-4 col-md-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
								<ng-template #zero>
									<div *ngIf="post.id==33 && memoryId==33 else free"
										class="col-lg col-4 col-md-4 col-sm-4 p-4">
										Included</div>
									<ng-template #free>
										<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
									</ng-template>
								</ng-template>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">A standard base system comes with 16GB DDR4 3000MHz and
						can be upgraded.
					</div>
					<hr class="dark mt-5">
					<!-- network start -->
					<h4 class="py-4 font-weight-bold p-2">Network</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Speed</div>
						<div class="col">Bandwidth</div>
						<div class="col">Static IPv4</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Network'" (click)="networkCost(post.optionPricePerMo,post.id)">
							<div [ngClass]="networkId==post.id? 'active-pack':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.optionName}}</div>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute1}}
									</div>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute2}}
									</div>
									<div *ngIf="post.optionPricePerMo>0 else zero"
										class="col-lg col-3 col-md-3 col-sm-3 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
								<ng-template #zero>
									<div *ngIf="post.id==41 && networkId==41 else free"
										class="col-lg col-3 col-md-3 col-sm-4 p-4">
										Included</div>
									<ng-template #free>
										<div class="col-lg col-3 col-md-3 col-sm-3 p-4">Free</div>
									</ng-template>
								</ng-template>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">All dedicated servers start with 75Mbit/s with
						completely unlimited bandwidth, which can be upgraded.
					</div>
					<hr class="dark mt-5">
					<div id="accordion">
						<div>
							<div class="card-header p-3 bg-white">
								<a class="py-4 font-weight-bold p-2" data-toggle="collapse" href="#storageA">Storage A
								</a>
							</div>
							<div id="storageA" class="collapse show" data-parent="#accordion">
								<div class="card-body">
									<div class="row text-center font-weight-bold">
										<div class="col">Double Drive Parity</div>
										<div class="col">Capacity</div>
										<div class="col">Drives</div>
										<div class="col">Price</div>
									</div>
									<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
										<a *ngIf="post.optionCategory=='Storage A'"
											(click)="storeA(post.optionPricePerMo,post.id)">
											<div [ngClass]="(storeAId==post.id && post.optionPricePerMo==storageA)? 'active-pack':''"
												class="row text-center my-3 option">
												<ng-container>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.optionName}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute1}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute2}}
													</div>
													<div *ngIf="post.optionPricePerMo==0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														Free</div>
													<div *ngIf="post.optionPricePerMo>0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														₹{{post.optionPricePerMo}}/Mo
													</div>
												</ng-container>
											</div>
										</a>
									</div>
									<div class="col text-secondary option-text">Storage A contains 7200RPM
										Enterprise
										Grade
										Drives.
										These setups come with Double Drive Parity.
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="card-header p-3 bg-white ">
								<a class="collapsed py-4 font-weight-bold p-2" data-toggle="collapse"
									href="#storageB">Storage B
								</a>
							</div>
							<div id="storageB" class="collapse" data-parent="#accordion">
								<div class="card-body">
									<div class="row text-center font-weight-bold">
										<div class="col">Double Drive Parity</div>
										<div class="col">Capacity</div>
										<div class="col">Drives</div>
										<div class="col">Price</div>
									</div>
									<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
										<a *ngIf="post.optionCategory=='Storage B'"
											(click)="storeB(post.optionPricePerMo,post.id)">
											<div [ngClass]="(storeBId==post.id && post.optionPricePerMo==storageB)? 'active-pack':''"
												class="row text-center my-3 option">
												<ng-container>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.optionName}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute1}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute2}}
													</div>
													<div *ngIf="post.optionPricePerMo==0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														Free</div>
													<div *ngIf="post.optionPricePerMo>0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														₹{{post.optionPricePerMo}}/Mo
													</div>
												</ng-container>
											</div>
										</a>
									</div>
									<div class="col text-secondary option-text">Storage B contains 7200RPM Enterprise
										Grade
										Drives.
										These setups come with Single Drive Parity.
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="card-header p-3 bg-white ">
								<a class="collapsed py-4 font-weight-bold p-2" data-toggle="collapse"
									href="#storageC">Storage C
								</a>
							</div>
							<div id="storageC" class="collapse" data-parent="#accordion">
								<div class="card-body">
									<div class="row text-center font-weight-bold">
										<div class="col">Double Drive Parity</div>
										<div class="col">Capacity</div>
										<div class="col">Drives</div>
										<div class="col">Price</div>
									</div>
									<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
										<a *ngIf="post.optionCategory=='Storage C'"
											(click)="storeC(post.optionPricePerMo,post.id)">
											<div [ngClass]="(storeCId==post.id && post.optionPricePerMo==storageC)? 'active-pack':''"
												class="row text-center my-3 option">
												<ng-container>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.optionName}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute1}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute2}}
													</div>
													<div *ngIf="post.optionPricePerMo==0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														Free</div>
													<div *ngIf="post.optionPricePerMo>0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														₹{{post.optionPricePerMo}}/Mo
													</div>
												</ng-container>
											</div>
										</a>
									</div>
									<div class="col text-secondary option-text">Storage C contains 5400RPM NAS Grade
										Drives.
										These
										setups come with Double Drive Parity.
									</div>
								</div>
							</div>
						</div>
						<div>
							<div class="card-header p-3 bg-white">
								<a class="collapsed py-4 font-weight-bold p-2" data-toggle="collapse"
									href="#storageD">Storage D
								</a>
							</div>
							<div id="storageD" class="collapse" data-parent="#accordion">
								<div class="card-body">
									<div class="row text-center font-weight-bold">
										<div class="col">Double Drive Parity</div>
										<div class="col">Capacity</div>
										<div class="col">Drives</div>
										<div class="col">Price</div>
									</div>
									<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
										<a *ngIf="post.optionCategory=='Storage D'"
											(click)="storeD(post.optionPricePerMo,post.id)">
											<div [ngClass]="(storeDId==post.id && post.optionPricePerMo==storageD)? 'active-pack':''"
												class="row text-center my-3 option">
												<ng-container>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.optionName}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute1}}
													</div>
													<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
														{{post.additionalAttribute2}}
													</div>
													<div *ngIf="post.optionPricePerMo==0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														Free</div>
													<div *ngIf="post.optionPricePerMo>0"
														class="col-lg col-3 col-md-3 col-sm-3 p-4">
														₹{{post.optionPricePerMo}}/Mo
													</div>
												</ng-container>
											</div>
										</a>
									</div>
									<div class="col text-secondary option-text">Storage D contains 5400RPM NAS Grade
										Drives.
										These
										setups come with Single Drive Parity.
									</div>
									<div class="col text-secondary option-text">Setup Cost: Rs. 5,499 - One Time Fee
										I
										Minimum Order: 6
										Months Contract I SLA: 96%
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr class="dark mt-5">
					<!-- graphics start -->
					<h4 class="py-4 font-weight-bold p-2">Graphics</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Processor</div>
						<div class="col">Graphic Card</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Graphics'"
							(click)="graphicsCost(post.optionPricePerMo,post.id)">
							<div [ngClass]="graphicsId==post.id? 'active-pack':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										{{post.optionName}}</div>
									<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo>0 else zero"
										class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
								<ng-template #zero>
									<div *ngIf="post.id==73 && graphicsId==73 else free"
										class="col-lg col-4 col-md-4 col-sm-4 p-4">
										Included</div>
									<ng-template #free>
										<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
									</ng-template>
								</ng-template>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">Choose between basic-workstation or professional grade
						graphic cards as per your needs.
					</div>
					<hr class="dark mt-5">
					<h4 class="pt-4 font-weight-bold p-2">Notes?</h4>
					<mat-form-field appearance="outline" class="px-lg-0 px-5 w-100">
						<mat-label>Details</mat-label>
						<textarea matInput placeholder="More customization"></textarea>
					</mat-form-field>
					<hr class="dark">
					<!--FAQs-->
					<div class="pt-4 font-weight-bolder px-2 faq-head">FAQ</div>
					<h5 class="font-weight-bold pb-2">(Must read before purchasing)</h5>

					<div class="faq-question pt-3">1. Why choose Kenmark ITan Solutions as your provider for Dedicated
						Servers?</div>
					<div class="faq-ans">Answer: Simply because we can customize your servers according to what you
						require. Providing the latest tech to help you succeed.</div>

					<div class="faq-question pt-3">2. What is unRAlD OS?</div>
					<div class="faq-ans">Answer: An OS takes a completely new approach to JBOD and RAID. It allows you
						to keep expanding your storage without completely resetting your RAID Configuration. It also
						allows you to add different sized drives to your array, without any complications. This
						Linux-based OS acts as a Network Attached Storage (NAS), Application Server (using Docker
						Containers) and Visualization Host (using QEMU and KVM).</div>

					<div class="faq-question pt-3">3. How does unRAlD deal with a Hard Disk Failure?</div>
					<div class="faq-ans">Answer: As soon as a Hard Disk Failure is detected, unRAlD stops using the
						drive and emulates it until the user restarts the machine with a new HDD. The emulated data is
						the re-build onto the new HDD as soon as the array starts. You should have a recovered drive
						within 12 hours.</div>

					<div class="faq-question pt-3">4. Can I get my dedicated server without unRAlD OS?
					</div>
					<div class="faq-ans">Short Answer: Yes!</div>
					<div class="faq-ans">
						Long Answer: Yes, but this is not recommend. Since unRAlD takes care of your Data Redundancy,
						Protection and Parity, without it you will be left with JBOD. unRAlD also provides the Web
						Interface as well as the Visualization Host, without which you will have to set this up manually
						on your selected os.
					</div>

					<div class="faq-question pt-3">5.How can I run Windows or Linux on my dedicated server?</div>
					<div class="faq-ans">Answer: We can set this up as a Virtual Machine on your Dedicated Server
						with/without CPU Passthrough. We can allocate RAM & Disk Size according to your host machine.
					</div>

					<div class="faq-question pt-3">6. Can I run a Mail Server on my Dedicated Server?</div>
					<div class="faq-ans">Answer: We do not allow personal / commercial mail servers on Dedicated
						Servers. If this is something you really want, please speak to us before purchasing your server.
					</div>

					<div class="faq-question pt-3">7. Can I get a discount on the Dedicated Server, if I buy this for a
						fixed term?</div>
					<div class="faq-ans">Answer: As soon as a Hard Disk Failure is detected, unRAlD stops using the
						drive and emulates it until the user restarts the machine with a new HDD. The emulated data is
						the re-build onto the new HDD as soon as the array starts. You should have a recovered drive
						within 12 hours.</div>

					<div class="faq-question pt-3">8. Will Kenmark ITan Solutions take care of any Hardware Issues /
						Drive Crashes / unRAlD Failures? </div>
					<div class="faq-ans">Answer: If you notice a problem on the server, please contact us and we can
						check any failures and exchange the hardware if necessary.</div>


					<div class="faq-question pt-3">9. Will Kenmark ITan Solutions be mirroring my Data to a Backup
						Server?</div>
					<div class="faa-ans">Answer: No! You are responsible to backup your data. We recommend our backup
						space which you can choose as an Add-on during your online order.</div>

					<div class="faq-question pt-3">10. Can a RAID failure on your Dedicated Servers mean that I lose all
						of my Data?</div>
					<div class="faq-ans">Answer: We don't use RAID and instead use a software alternative called unRAlD,
						which prevents complete data loss in a situation of multiple drive failures.</div>

					<div class="faq-question pt-3">11. Can I get Hardware RAID instead of unRAlD?</div>
					<div class="faq-ans">Answer: Hard RAID is chargeable and requires a fresh installation of your
						server.</div>

					<div class="faq-question pt-3">12. What is the maximum storage you can provide?</div>
					<div class="faq-ans">Answer: The biggest HDD we provide is a IOTB and with single disk parity you
						can upgrade your storage to 50TB. For higher configurations, a higher grade dedicated server
						would be required.</div>

					<div class="faq-question pt-3">13. What is the guarantee that Kenmark ITan Solutions protects my
						Data against Hardware Failures? </div>
					<div class="faq-ans">Answer: Our hardware is always 'state of the art', but we cannot guarantee a
						100% against hardware failures. We always recommend a backup.</div>

					<div class="faq-question pt-3">14. What happens if my server is down?</div>
					<div class="faq-ans">Answer: Report it! We will look into it ASAP. Any downtime would be compensated
						into your next month with the amount of credits mentioned above.</div>
					<br><br>
				</div>
			</div>
		</div>
	</div>
	<div class="row amount p-lg-4 justify-content-center p-3 p-md-4">
		<div class="text-center ml-lg-auto mr-lg-4 text-lg-right">
			<div class="option-cost text-center px-4">
				{{((pack.priceINR)+os+memory+network+storageA+storageB+storageC+storageD+graphics | currency:'INR') || '0'}}
				<a routerLink="/cart" class="ken-btn p-2 p-lg-2 px-2 px-lg-4 mx-lg-2 text-white">
					Continue
				</a>
			</div>
		</div>
	</div>
</div>