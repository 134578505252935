import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocalCacheService {


  constructor(
    /* private _callHttp: CallHttp, */
    private _localstorage: LocalStorageService,
    private _router: Router
  ) { }


  // Basic operations
  clearCache() {
    return this._localstorage.clearCache();
  }

  setItem(key, value) {
    return this._localstorage.setItem(key, value);
  }

  getItem(key) {
    return this._localstorage.getItem(key);
  }

  updateItem(key,value) {
    return this.removeItem(key).then(res=>{
      return this._localstorage.setItem(key,value);
    });
  }

  removeItem(key) {
    return this._localstorage.removeItem(key);
  }


  /* getAuthTok() {
    return this._localstorage.getItem(Const.AUTH_TOKEN);
  }

  getUser() {
    return this._localstorage.getItem(Const.USER);
  } */

  intiateLogOut() {
    /* localStorage.clear(); */
    /* this._callHttp.clearHeaders(); */
    this._localstorage.clearCache();
    this._router.navigate(['']);
}
}
