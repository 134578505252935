import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class HostingCustomService {
  posts;
  constructor(private http: HttpClient) { }

  getAll(id) {
    return this.http.get("https://kits.api-central.kenmarkserver.com/products/" + id + "/cust")
      .pipe(map(res => this.posts = res),
        catchError(this.errorHandler));
  }
  errorHandler(error) {
    let errorMessage = '';
    if (error.code == 500) { errorMessage = 'Server is offline' }
    else if (error.code == 503) { errorMessage = "You're not connected to the internet" }
    else { errorMessage = error.error.message; }
    return throwError(errorMessage);
  }
}