import { Component, OnInit } from '@angular/core';
import { HostingCustomService } from 'src/app/services/hosting-custom.service';
import { HostingService } from 'src/app/services/hosting.service';

@Component({
  selector: 'app-dedicated-custom',
  templateUrl: './dedicated-custom.component.html',
  styleUrls: ['./dedicated-custom.component.scss']
})
export class DedicatedCustomComponent implements OnInit {
  posts: any;
  pack: any;

  os: number = 0;
  storageA: number = 0;
  storageB: number = 0;
  storageC: number = 0;
  storageD: number = 0;
  memory: number = 0;
  network: number = 0;
  graphics: number = 0;

  storeAId: number;
  storeBId: number;
  storeCId: number;
  storeDId: number;
  osId: number = 28;
  memoryId: number = 33;
  networkId: number = 41;
  backupId: number;
  graphicsId: number = 73;

  constructor(private service: HostingCustomService, private sharing: HostingService) { }
  ngOnInit() {
    this.service.getAll("2").subscribe(post => this.posts = post);
    this.sharing.currentPack.subscribe(message => this.pack = message);
  }
  osCost(cost, id) {
    this.os = cost;
    this.osId = id;
  }
  memoryCost(cost, id) {
    this.memory = cost;
    this.memoryId = id;
  }
  networkCost(cost, id) {
    this.network = cost;
    this.networkId = id;
  }
  graphicsCost(cost, id) {
    this.graphics = cost;
    this.graphicsId = id;
  }
  storeA(cost, id) {
    this.storageA = cost;
    this.storeAId = id;
    this.storageB = 0;
    this.storageC = 0;
    this.storageD = 0;
  }
  storeB(cost, id) {
    this.storageB = cost;
    this.storeBId = id;
    this.storageA = 0;
    this.storageC = 0;
    this.storageD = 0;
  }
  storeC(cost, id) {
    this.storageC = cost;
    this.storeCId = id;
    this.storageA = 0;
    this.storageB = 0;
    this.storageD = 0;
  }
  storeD(cost, id) {
    this.storageD = cost;
    this.storeDId = id;
    this.storageA = 0;
    this.storageB = 0;
    this.storageC = 0;
  }
}
