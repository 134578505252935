<!-- <div fullpage id="fullpage" [options]="config" (ref)="getRef($event)" #fullpageRef> -->
  <div>
  <!--==============================Jumbo Start===================================-->
  <!-- <div class="section" id="section0"> -->
    <div class="section">
    <div class="container-fluid fulllHeight">
      <div class="row p-lg-5 bg-light-img">
        <div class="col-12 text-center p-lg-3">
          <p class="heading">Learn. Create. Impress.</p>
          <p class="subHeading pt-0">ONE STOP SHOP FOR ALL YOUR IT SOLUTIONS</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 19.png" class="img-fluid" alt="web_img">
            <p class='pt-5 serviceName'>Web Hosting</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Web Hosting</p>
            <p class='text-white px-3 paraTwo'>Hosting the pride and joy of your buisness, 24 x 7 x 365.</p>
          </div>
        </div>

        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 20.png" class="img-fluid" alt="development_img">
            <p class="pt-5 serviceName">Development</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Development</p>
            <p class='text-white px-3 paraTwo'>Transform your imagination into an online reality.</p>
          </div>
        </div>

        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 18.png" class="img-fluid" alt="designing_img">
            <p class="pt-5 serviceName">Design</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Design</p>
            <p class='text-white px-3 paraTwo'>Be it UI/UX or graphics, we help bring your ideas to life</p>
          </div>
        </div>

        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 17.png" class="img-fluid" alt="branding_img">
            <p class='pt-5 serviceName'>Branding</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Branding</p>
            <p class='text-white px-3 paraTwo'>Branding solution tailored to your brand identity.</p>
          </div>
        </div>

        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 21.png" class="img-fluid" alt="marketing_img">
            <p class="pt-5 serviceName">Marketing</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Marketing</p>
            <p class='text-white px-3 paraTwo'>From SEO, SMM to everything offline, we provide integrated marketing
              solutions
              for your needs.</p>
          </div>
        </div>

        <div class="col-lg col-6 body text-center services">
          <div class="alwaysVisible">
            <img src="../../../assets/images/icons/Mask Group 22.png" class="img-fluid" alt="consultancy_img">
            <p class='pt-5 serviceName'>Consultancy</p>
          </div>
          <div class="onlyHover">
            <p class='px-3 paraOne'>Consultancy</p>
            <p class='text-white px-3 paraTwo'>Provide third-party guidance, feedback and technical advice</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--==============================Jumbo End===================================-->



  <!--==============================features Start===================================-->
  <!-- <div class="section" id="section2"> -->
    <div class="section">
    <div class="container-fluid feature text-center fulllHeight d-flex align-items-center">
      <div class="row">
        <div class="col feature-col px-5">
          <div class="feature-title">
            <p class='mb-n2'>More</p>
            <abbr>FLEXIBILITY</abbr>
          </div>
          <p class="feature-content px-2 pt-lg-4">Services tailored to your needs, according to your timelines.
          </p>
        </div>
        <div class="col feature-col px-5">
          <div class="feature-title">
            <p class='mb-n2'>More</p>
            <abbr>SIMPLICITY</abbr>
          </div>
          <p class="feature-content px-2 pt-lg-4">Solutions built for problems of various complexities SMB or enterprise
            level.
          </p>
        </div>
        <div class="col feature-col px-5">
          <div class="feature-title">
            <p class='mb-n2'>More</p>
            <abbr>CLARITY</abbr>
          </div>
          <p class="feature-content px-2 pt-lg-4">At most transparency and unambiguity available from our team, at every
            stage of the
            process.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!--==============================features End===================================-->

  <!--==============================Jumbo-2 Start===================================-->
  <!-- <div class="section" id="section3"> -->
    <div class="section">
    <div class="container-fluid p-5 fulllHeight text-center d-flex justify-content-center flex-column"
      style="background-image: url('../../../assets/images/Background.png');">
      <p class="jumbo2Text">We want to work with you.</p>
      <p class="jumbo2TextTwo">
        No project is too big or too small, we custom build our solutions for each client.
        Looking for something specific but didn’t find it on our website? Contact us for more specialized
        services.<br><br>
        Cutting edge technology, we love taking up a challenge.
      </p>
    </div>
  </div>
  <!--==============================Jumbo-2 End===================================-->

  <!--==============================Service start===================================-->
  <!-- <div class="section" id="section1"> -->
    <div class="section">
    <div class="container-fluid fulllHeight bg-dark-img text-justify p-0" style="background-size: cover;">
      <div class=" row text-center p-3 m-0">
        <div class="col-lg-12">
          <p class="m-0 font-weight-lighter kenmarkQuote">At Kenmark ITan Solutions we continuously strive to perfect our
            service, these are just a few of the attributes we like to boast about:</p>
        </div>
      </div>

      <!-- <div class="bg-dark-img"> -->
        <div class="row text-center m-0">
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 11.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Local Buisness to Mumbai and Conventry</p>
          </div>
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 12.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Providers of remote assistance overseas</p>
          </div>
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 13.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Friendly and simple talking advice and service</p>
          </div>
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 16.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Professional and reliable service</p>
          </div>
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 14.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Genuine approach to all endeavours</p>
          </div>
          <div class="col-lg-4 p-5">
            <img src="../../../assets/images/icons/Mask Group 15.png" class="img-fluid" alt="web_img">
            <br><br>
            <p class="serviceTxt">Flexible hours to suit your needs</p>
          </div>
        </div>
      <!-- </div> -->

    </div>
  </div>
  <!--==============================Service start===================================-->



  <!--==============================clients Start===================================-->
  <!-- <div class="section" id="section4"> -->
    <div class="section">
    <div *ngIf="posts" class="container-fluid t-lg-5 fulllHeight">
      <div class="row text-center text-lg-left">
        <div *ngFor="let post of posts.data" class="col-lg-3 col-md-4 col-6" style="border: 1px solid #bebebe;">
          <a class="d-block mb-4 h-100">
            <img class="img-fluid" src="https://kits.api-central.kenmarkserver.com/home{{post.photo}}" alt="">
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--==============================clients End===================================-->
  <!-- <div class="section" id="section5"> -->
    <div class="section">
    <div class="container-fluid t-lg-5 fulllHeight">
      <div class="container largeDevicePadding">
      <div class="row">
        <div class="col-12 col-lg-6 imgCol">
          <img src="assets/images/Mask Group 26.png" alt="Img">
        </div>
        <div class="col-12 col-lg-6">
          <p class="footerPara">Have a special request?</p>
          <div class="input-group mb-3">
          <p class="footerParaOne">Join our Mailing List &nbsp;</p>
          <input type="text" class="form-control" placeholder="Enter your Email" aria-label="Recipient's username" aria-describedby="basic-addon2">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" style="height: 38px;" type="button">></button>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- <div class="row pt-lg-3 justify-content-center align-items-center onlyforLaptop" style="background-color:black">
        <div class="col-lg-2 p-3">
          <ul class="list-unstyled text-white">
            <li class="font-weight-bold pb-2" routerLink='/contact'><a class="text-white">CONTACT US</a></li>
            <li href="tel:+91-9820283097" class="mb-2"><a>+91-9820283097</a></li>
            <li class="mb-2"><a href='mailto:itan@kenmark.in'>itan@kenmark.in</a> </li>
            <li><a>601-604,Chaitanya CHS LTD, <br>Near Ram Mandir Signal, Goregaon West,<br> Mumbai - 400104.</a></li>
          </ul>
        </div>
        <div class="col-lg-2 p-3">
          <ul class="list-unstyled text-white">
            <li class="font-weight-bold pb-2"><a class="text-white">Company</a></li>
            <li class="mb-2"><a>About Us</a></li>
            <li class="mb-2"><a>Courses</a></li>
            <li class="mb-2"><a routerLink="/policies">Privacy Policy</a></li>
            <li class="mb-2"><a routerLink="/policies">Refund Policy</a></li>
            <li class="mb-2"><a routerLink="/policies">Terms & Conditions</a></li>
          </ul>
        </div>
        <div class="col-lg-2 p-3">
          <ul class="list-unstyled">
            <li class="font-weight-bold pb-2"><a class="text-white">KiTS+</a></li>
            <li class="mb-2"><a>Careers</a></li>
            <li class="mb-2"><a>Summer Internship Program</a></li>
            <li class="mb-2"><a>Partner with us</a></li>
            <li class="mb-2"><a>KiTS Classroom</a></li>
            <li class="mb-2"><a>KiTS Hackathon</a></li>
          </ul>
        </div>
        <div class="col-lg-2 p-3">
          <ul class="list-unstyled">
            <li class="font-weight-bold pb-2"><a class="text-white">Sevices</a></li>
            <li class="mb-2"><a>Web Hosting</a></li>
            <li class="mb-2"><a>Development</a></li>
            <li class="mb-2"><a>Branding & Design</a></li>
            <li class="mb-2"><a>Marketing</a></li>
            <li class="mb-2"><a>Consultancy</a></li>
          </ul>
        </div>
        <div class="col-lg-2 p-3 text-lg-right">
          <ul class="list-unstyled">
            <li class="font-weight-bold pb-2">
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Twitter – 6.png" alt="Twitter"></a>
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Facebook – 6.png" alt="Facebook"></a>
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Instagram – 6.png" alt="Instagram"></a>
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 16.png" alt="LinkdIn"></a>
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 13.png" alt="Mail"></a>
              <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 15.png" alt="Phone"></a>
            </li>
            <li>
              <a routerLink='/home'>
                <img src="assets/images/4.png" class="align img-fluid" alt="footerLogo">
              </a>
            </li>
          </ul>
        </div>
      </div> -->
    </div>
  </div>

</div>
