<mat-tab-group mat-align-tabs="center">
    <mat-tab label="Privacy Policy">
        <div class="container-fluid">
            <div class="row p-5 bg-light-img text-center">
                <div class="col pt-lg-5">
                    <p class="heading font-weight-lighter pt-lg-5">The important stuff</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 bg-color d-flex justify-content-center align-items-center border border-white">
                    <p class="headText">Privacy Policy</p>
                </div>
                <div class="col-lg-9 p-5">
                    <div class="scrollableText">
                        <p class="paraText">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Bibendum est ultricies
                            integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor
                            orci dapibus ultrices in. Egestas diam in
                            arcu cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt ornare massa eget egestas purus viverra accumsan
                            in nisl. Tempor id eu nisl nunc
                            mi ipsum faucibus. Fusce id velit ut tortor pretium. Massa ultricies mi quis hendrerit dolor magna eget. Nullam eget
                            felis eget nunc lobortis. Faucibus
                            ornare suspendisse sed nisi. Sagittis eu volutpat odio facilisis mauris sit amet massa. Erat velit scelerisque in
                            dictum non consectetur a erat. Amet
                            nulla facilisi morbi tempus iaculis urna. Egestas purus viverra accumsan in nisl. Feugiat in ante metus dictum at
                            tempor commodo. Convallis tellus id
                            interdum velit laoreet. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Viverra aliquet eget sit amet
                            tellus cras adipiscing enim eu. Ut
                            faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Dui faucibus in ornare quam. In iaculis nunc
                            sed augue lacus viverra vitae
                            congue. Vitae tempus quam pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue interdum. Sem fringilla ut
                            morbi tincidunt augue. Morbi
                            enim nunc faucibus a pellentesque sit amet porttitor eget. In est ante in nibh mauris. Nam aliquam sem et tortor
                            consequat id porta nibh.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="T&Cs">
        <div class="container-fluid">
            <div class="row p-5 bg-light-img text-center">
                <div class="col pt-lg-5">
                    <p class="heading font-weight-lighter pt-lg-5">The important stuff</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 bg-color d-flex justify-content-center align-items-center border border-white">
                    <p class="headText">T&Cs</p>
                </div>
                <div class="col-lg-9 p-5">
                    <div class="scrollableText">
                        <p class="paraText">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Bibendum est ultricies
                            integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor
                            orci dapibus ultrices in. Egestas diam in
                            arcu cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt ornare massa eget egestas purus viverra accumsan
                            in nisl. Tempor id eu nisl nunc
                            mi ipsum faucibus. Fusce id velit ut tortor pretium. Massa ultricies mi quis hendrerit dolor magna eget. Nullam eget
                            felis eget nunc lobortis. Faucibus
                            ornare suspendisse sed nisi. Sagittis eu volutpat odio facilisis mauris sit amet massa. Erat velit scelerisque in
                            dictum non consectetur a erat. Amet
                            nulla facilisi morbi tempus iaculis urna. Egestas purus viverra accumsan in nisl. Feugiat in ante metus dictum at
                            tempor commodo. Convallis tellus id
                            interdum velit laoreet. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Viverra aliquet eget sit amet
                            tellus cras adipiscing enim eu. Ut
                            faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Dui faucibus in ornare quam. In iaculis nunc
                            sed augue lacus viverra vitae
                            congue. Vitae tempus quam pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue interdum. Sem fringilla ut
                            morbi tincidunt augue. Morbi
                            enim nunc faucibus a pellentesque sit amet porttitor eget. In est ante in nibh mauris. Nam aliquam sem et tortor
                            consequat id porta nibh.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
    <mat-tab label="Refund Policy">
        <div class="container-fluid">
            <div class="row p-5 bg-light-img text-center">
                <div class="col pt-lg-5">
                    <p class="heading font-weight-lighter pt-lg-5">The important stuff</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 bg-color d-flex justify-content-center align-items-center border border-white">
                    <p class="headText">Refund Policy</p>
                </div>
                <div class="col-lg-9 p-5">
                    <div class="scrollableText">
                        <p class="paraText">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                            aliqua. Bibendum est ultricies
                            integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae ultricies leo integer malesuada. Ac odio tempor
                            orci dapibus ultrices in. Egestas diam in
                            arcu cursus euismod. Dictum fusce ut placerat orci nulla. Tincidunt ornare massa eget egestas purus viverra accumsan
                            in nisl. Tempor id eu nisl nunc
                            mi ipsum faucibus. Fusce id velit ut tortor pretium. Massa ultricies mi quis hendrerit dolor magna eget. Nullam eget
                            felis eget nunc lobortis. Faucibus
                            ornare suspendisse sed nisi. Sagittis eu volutpat odio facilisis mauris sit amet massa. Erat velit scelerisque in
                            dictum non consectetur a erat. Amet
                            nulla facilisi morbi tempus iaculis urna. Egestas purus viverra accumsan in nisl. Feugiat in ante metus dictum at
                            tempor commodo. Convallis tellus id
                            interdum velit laoreet. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Viverra aliquet eget sit amet
                            tellus cras adipiscing enim eu. Ut
                            faucibus pulvinar elementum integer enim neque volutpat ac tincidunt. Dui faucibus in ornare quam. In iaculis nunc
                            sed augue lacus viverra vitae
                            congue. Vitae tempus quam pellentesque nec nam aliquam sem et. Ut morbi tincidunt augue interdum. Sem fringilla ut
                            morbi tincidunt augue. Morbi
                            enim nunc faucibus a pellentesque sit amet porttitor eget. In est ante in nibh mauris. Nam aliquam sem et tortor
                            consequat id porta nibh.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>