<div class="container-fluid p-0">
    <div class="row bg-img-light justify-content-center customPadding m-0">
        <div class="col-12 pb-5 text-center">
            <p class="totalText">Your total is $3,800.00</p>
        </div>
        <div class="col-3 ken-btn p-2 mr-4 text-center">Continue Shopping</div>
        <div class="col-3 ken-btn p-2 ml-4 text-center" routerLink="/checkout">Checkout</div>
    </div>
</div>
<div style="background-color: #eee;">
    <div class="container py-4">
        <div class="row">
            <div class="col-3">
                <p class="m-0 packageType">VPS-Bronze</p>
            </div>
            <div class="col-3 d-flex align-items-center">
                <p class="m-0 productDetails dropdown-toggle" (click)="showProDetails()">Show product details</p>
            </div>
            <div class="col-3 text-right">
                <p class="m-0 quantity"><span>-</span>&nbsp;&nbsp;&nbsp;<span style="color: #707070;">1</span>&nbsp;&nbsp;&nbsp;<span>+</span></p>
            </div>
            <div class="col-3 text-right">
                <p class="m-0 price">₹3,899.00</p>
            </div>
        </div>
        <div class="row pt-5" *ngIf="proDetails">
            <div class="col-6">
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
            </div>
            <div class="col-6">
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
            </div>
        </div>
    </div>
</div>
