import { Router, UrlTree } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  constructor(public router: Router) { }
  isActive() {
    switch (this.router.url) {
      case '/hosting/vps':
      case '/hosting/vps/custom':
      case '/hosting/dedicated':
      case '/hosting/dedicated/custom':
      case '/hosting/dedicated':
      case '/hosting/web-hosting':
      case '/hosting/web-hosting/custom':
      case '/hosting/cloudStorage':
      case '/hosting/cloudStorage/custom':
        return true
      default:
        return false
        break;
    }
  }
  isActiveContact(){
    switch (this.router.url) {
      case '/contact-details':
      case '/contact-form':
        return true
      default:
        return false
        break;
    }
  }

  isActiveCompany(){
    switch (this.router.url) {
      case '/about':
      case '/our-work':
        case '/our-team':
        return true
      default:
        return false
        break;
    }
  }
  showcompany(){
    document.getElementById("CompanyNav").style.display = "block";
    document.getElementById("ContactNav").style.display = "none";
    document.getElementById("hostingNav").style.display = "none";
  }
  showhosting(){
    document.getElementById("CompanyNav").style.display = "none";
    document.getElementById("ContactNav").style.display = "none";
    document.getElementById("hostingNav").style.display = "block";
  }
  showcontact(){
    document.getElementById("CompanyNav").style.display = "none";
    document.getElementById("ContactNav").style.display = "block";
    document.getElementById("hostingNav").style.display = "none";
  }
  shownone(){
    document.getElementById("CompanyNav").style.display = "none";
    document.getElementById("ContactNav").style.display = "none";
    document.getElementById("hostingNav").style.display = "none";
  }
}
