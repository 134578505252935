import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {



  /**
 *
 * @param key
 * @param value
 * @returns {any}
 */

constructor() {
  let options = {
    driver: localforage.INDEXEDDB,
    name: 'PlatformApp',
    version: 1.0,
    storeName: 'platform_main',
    description: 'social application'
  };
  localforage.config(options);
}

public setItem<T>(key: string, value: T) {
  return localforage.setItem(key, value);
}

/**
 *
 * @param key
 * @returns {any}
 */
public getItem(key: string) {
  return localforage.getItem(key);
}

/**
 *
 * @param key
 * @returns {any}
 */
public removeItem(key: string) {
  return localforage.removeItem(key);
}

/**
*
* @returns {any}
*/
public clearCache() {
  return localforage.clear();
}
}
