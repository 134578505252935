<!-- <div *ngFor='let post of posts'>
    <div *ngIf='post.content_category=="About KiTS"'>
      <div class="jumbo text-center text-justify py-2 py-lg-5">
        <div class="container-fluid px-lg-5 pt-lg-5 pt-2">
          <div class=" text-white px-lg-5 px-2 pt-5 font-weight-lighter">
            <div class="heading pb-5">{{post.content_category}}</div>
            <p class="section-body gray mx-lg-4 px-lg-5">{{post.description}}</p>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<div class="container-fluid  m-0">
  <div class="row justify-content-center p-lg-5 bg-light-img">
    <div class="section-head p-lg-5 p-3">Our Founder</div>
  </div>
  <!-- <div class="row m-0">
    <div class="swiper-container-founder">
      <div class="swiper-wrapper">
        <div class="swiper-slide"> -->
          <div class="row">
            <div class="col-12 col-lg-6 p-0">
              <img class="FounderImage img-fluid w-100" src="assets/images/our-team/Tanooj.jpg" alt="Photo">
            </div>
            <div class="col-12 col-lg-6 p-0" style="background-color: #eeeeee;">
              <div class="row m-0">
                
                <div class="col-12 text-left">
                  <p class="founderDesp">
                      Business, IT and Leadership comes naturally to me. Confident approach to work, effective and efficiently utilize skills to maximum potential. Aware of colleagues, and work well within teams, support others to achieve. Able to communicate well with customers, delivering a range of technical information to ensure client understanding.
                      Able to communicate well with customers, delivering a range of technical information to ensure client understanding. This has improved greatly-after my role as a Lecturer for Information Technology Practitioner & Digital Technical Solutions at CU Coventry.
                  </p>
                </div>
                <div class="founderDetails col-7 text-left">                  
                  <p class="founderPosition">BSc (Hons) Information Technology Practitioner, Coventry University</p>
                  <p class="founderPosition">Infrastructure | Networking | Business Analysis | Architecture</p>
                  <p class="founderName">Tanooj Sanjeev Mehra</p>
                  <p class="founderPosition">Founder, CTO</p>
                </div>
                <div class="founderSocials col-5 text-right">
                  <a class="SocailIcon" href="https://www.facebook.com/TJMehra" target="_blank"><img width="18px" src="assets/images/our-team/facebook.png" alt=""></a>
                  <a class="SocailIcon" href="https://www.instagram.com/tjmehra/" target="_blank"><img width="18px" src="assets/images/our-team/instagram.png" alt=""></a>
                  <!-- <a class="SocailIcon"><img src="assets/images/our-team/Twitter.png" alt=""></a> -->
                  <a class="SocailIcon" href="https://tanoojmehra.com/" target="_blank"><img width="18px" src="assets/images/our-team/world-wide-web.png" alt=""></a>
                </div>
              </div>
            </div>
          </div>


  <div class="">
    <div class="row justify-content-center p-lg-5 bg-light-img" style="margin-bottom: 1px;">
      <div class="section-head p-lg-5">Meet the team</div>
    </div>

    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-2 p-lg-5">
      <div class="col-lg-6 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Prabhat.jpg" alt="">
        <div class="text-center">
          <h2> Prabhat Barai </h2>
          <p class="position"> Team Lead Programming </p> 
          <p> BSc IT, MCA </p> 
          <p class="skills"> Programming | Problem Solving | Networking </p>
        </div>
      </div>
      <div class="col-lg-6 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Pramod.jpg" alt="">
        <div class="text-center">
          <h2> Pramod Baghel </h2>
          <p> Deputy Team Lead Programming </p> 
          <p> BSc IT, MCA </p> 
          <p class="skills"> Programming | Problem Solving | Optimization </p>
        </div>
      </div>
    </div>
  <div class="row p-lg-5">
    <div class="col-lg-4 pb-5 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Vinay.jpg" alt="">
        <div class="text-center">
           <h2> Vinay Lula </h2> 
           <p> Team Lead QA </p> 
           <p> BEng ExTC</p>
           <p class="skills"> Business Analysis | Communication </p>
          </div>
        </div>
    <div class="col-lg-4 pb-5 text-center">
       <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Riddhi.jpg" alt="">
          <div class="text-center">
            <h2> Riddhi Rathod </h2> 
            <p> Team Lead Web </p> 
            <p> MCA </p> 
            <p class="skills"> UI | Integration </p>     
          </div>
        </div>
    <div class="col-lg-4 pb-5 text-center">
       <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Ashwini.jpg" alt="">
          <div class="text-center">
            <h2> Ashwini Samal </h2> 
            <p> Team Lead API </p> 
            <p> MCA </p> 
            <p class="skills"> API | Databases </p>
          </div>
    </div>
    <div class="col-lg-4 pb-5 text-center">  
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Saurabh.jpg" alt="">
          <div class="text-center">
            <h2> Saurabh S Savara </h2> 
            <p> International Consultant </p> 
            <p> BEng CS </p> 
            <p class="skills"> Programming | Troubleshooting </p>
        </div>
      </div>
    <div class="col-lg-4 pb-5 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Smaran.jpg" alt="">
          <div class="text-center">
            <h2> Smaran Adepu </h2> 
            <p> Consultant </p> 
            <p> BSc IT</p> 
            <p class="skills"> Programming | Troubleshooting </p>
        </div>
      </div>
    <div class="col-lg-4 pb-5 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Siddhant.jpg" alt="">        
          <div class="text-center">
            <h2> Siddhant Jangle </h2> 
            <p> Consultant </p> 
            <p> BCA </p> 
            <p class="skills"> UI | AppDevelopment </p>
        </div>
      </div>
    </div>
<div class="row p-lg-5">
  <div class="col-lg-3 pb-5 text-center">
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Ravi.jpg" alt="">
        <div class="text-center">
         <h2> Ravi singh </h2> 
         <p> Back-end Database </p> 
         <p> BSc CS </p> 
         <p class="skills"> API | Databases</p>
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
        <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Apurva.jpg" alt="">
        <div class="text-center">
          <h2> Apurva Desai </h2> 
          <p> Back-end Developer </p> 
          <p> BSc CS, MCA </p> 
          <p class="skills"> API | Databases </p>
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
     <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Bhavika.jpg" alt="">      
        <div class="text-center">
          <h2> Bhavika Solanki</h2> 
          <p> Frontend Developer </p> 
          <p> BSc IT, MCA </p> 
          <p class="skills"> UI | Validations </p>
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
      <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Pallavi.jpg" alt="">
      
        <div class="text-center">
          <h2> Pallavi Chandurkar </h2> 
          <p> Frontend Developer </p> 
          <p>  MCA </p> 
          <p class="skills"> UI | Validations </p>
          
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Kishore.jpg" alt="">
      
        <div class="text-center">
          <h2> Kishore Saun </h2> 
          <p> Frontend Developer </p> 
          <p> BSc IT </p> 
          <p class="skills"> UI | Integration </p>
        
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Saif.jpg" alt="">
      
        <div class="text-center">
          <h2> Saif Sayed</h2> 
          <p> Back-end Developer</p> 
          <p> BSc IT</p> 
          <p class="skills"> AI & ML | Automation </p>
      
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Prithvi.jpg" alt="">
      
        <div class="text-center">
          <h2> Prithvi Chauhan </h2> 
          <p> App Developer </p> 
          <p> MCA </p> 
          <p class="skills"> UI | Communication </p>
    
      </div>
    </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Umesh.jpg" alt="">
      
        <div class="text-center">
          <h2> Umesh Tikhe</h2> 
          <p> App Developer </p> 
          <p> </p> 
          <p class="skills"> UI | Integration </p>
          
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
     <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Santosh.jpg" alt="">
      
        <div class="text-center">
          <h2> Santosh Rajbhar </h2> 
          <p> App Developer </p> 
          <p>Pursuing BSc IT</p> 
          <p class="skills"> UI | Integration </p>
        
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
     <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Ekta.jpg" alt="">
      
        <div class="text-center">
          <h2> Ekta Chauhan  </h2> 
          <p> Frontend Developer </p> 
          <p> MCA </p> 
          <p class="skills"> UI </p>
        
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Supriya.jpg" alt="">
      
        <div class="text-center">
          <h2> Supriya Dhulap </h2> 
          <p>Frontend Developer </p> 
          <p> BSc IT </p> 
          <p class="skills"> UI </p>
      
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Anil.jpg" alt="">
      
        <div class="text-center">
          <h2> Anil Bhagwan</h2> 
          <p> Back-end Developer </p> 
          <p> BSc IT, MSc CS </p> 
          <p class="skills"> API | Database </p>
    </div>
    </div>
  <div class="col-lg-3 pb-5 text-center">
    
    <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Dishu.jpg" alt="">
      
        <div class="text-center">
          <h2> Dishu Herbha</h2> 
          <p> Back-end Developer </p> 
          <p> BSc IT </p> 
          <p class="skills"> API | Database </p>
          
  </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
      <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Bhumil.jpg" alt="">
      
        <div class="text-center">
          <h2> Bhumil Patel</h2> 
          <p> App Developer </p> 
          <p>Pursuing BEng CS </p> 
          <p class="skills"> UI | Integration </p>
          
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
      <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Shreyas.jpg" alt="">
      
        <div class="text-center">
          <h2> Shreyas Sai</h2> 
          <p> App Developer </p> 
          <p> Btech CS </p> 
          <p class="skills"> UI | Integration </p>
    </div>
  </div>
  <div class="col-lg-3 pb-5 text-center">
    
      <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Bhakti.jpg" alt="">
      
        <div class="text-center">
          <h2> Bhakti Chauhan </h2> 
          <p> QA Tester </p> 
          <p> MCA </p> 
          <p class="skills"> Testing | Reporting </p>
      
  </div>
</div>
<div class="col-lg-3 pb-5 text-center">
    
  <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Vineel.jpg" alt="">
  
    <div class="text-center">
      <h2> Vineel Bheemanadam </h2> 
      <p> Frontend Developer </p> 
      <p> Btech CS </p> 
      <p class="skills"> UI </p>
  
</div>
</div>
<div class="col-lg-3 pb-5 text-center">
    
  <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Suchendra.jpg" alt="">
  
    <div class="text-center">
      <h2> Suchendra Sharma  </h2> 
      <p> Frontend Developer </p> 
      <p> Btech IT </p> 
      <p class="skills"> UI | Integration </p>
  
</div>
</div>
<div class="col-lg-3 pb-5 text-center">
    
  <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Pratima.jpg" alt="">
  
    <div class="text-center">
      <h2> Pratima Vishwakarma </h2> 
      <p> Wordpress Developer </p> 
      <p> MCA </p> 
      <p class="skills"> UI | CMS </p>
  
</div>
</div>
<div class="col-lg-3 pb-5 text-center">
    
  <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Sanchari.jpg" alt="">
  
    <div class="text-center">
      <h2> Sanchari Das </h2> 
      <p> Wordpress Developer </p> 
      <p> MCA </p> 
      <p class="skills"> UI | CMS </p>
  
</div>
</div>
<div class="col-lg-3 pb-5 text-center">
    
  <img class="bd-placeholder-img rounded-circle" width="230" height="230" src="assets/images/our-team/Dhananjay.jpg" alt="">
  
    <div class="text-center">
      <h2> Dhananjay Ahuja </h2> 
      <p> Wordpress Developer </p> 
      <p> Pursuing MBA Tech IT </p> 
      <p class="skills"> UI | CMS </p>
  
</div>
</div>
</div>
  </div>
  







  <!-- <div class="row pt-2">
    <ng-container *ngFor='let post of posts'>
      <div class="col-lg col-sm-12 col-12 py-sm-2 px-lg-5" *ngIf='post.content_category=="Our Founders"'>
        <img src="https://kits.api-central.kenmarkserver.com/about/{{post.thumbnail}}">
        <div class="section-body pt-4 pb-1">{{post.name}}</div>
        <div class="section-body postTitle  pb-4" style="color: #77a1db;">{{post.title}}</div>
        <div class="section-body pb-lg-0 px-5 pb-5">{{post.description}}</div>
      </div>
    </ng-container>
  </div> -->

  <!--international-->
  <!-- <div class="text-center container-fluid p-lg-5 p-4 p-sm-4"
    style="background-image: url(./../../../assets/images/bg-light.png)">
    <div class="section-head pb-5">International Consultants</div>
    <div class="row pb-5">
      <ng-container *ngFor='let post of posts'>
        <div *ngIf='post.content_category=="International Consultants"' class="col-lg-3 col-sm-3 col-md-3 px-lg-5">
          <img src="https://kits.api-central.kenmarkserver.com/about/{{post.thumbnail}}" class="pt-3">
          <div class="section-body pt-4 pb-1">{{post.name}}</div>
          <div class="section-body postTitle pb-4" style="color: #77a1db;">{{post.title}}</div>
          <div class="section-body" style="color: #bebebe;">{{post.location}} </div>
        </div>
      </ng-container>
    </div>
  </div> -->
  <!--Core Team-->
  <!-- <div class="text-center container-fluid p-lg-5 p-4 p-sm-4"
    style="background-image: url(./../../../assets/images/bg-light.png); backdrop-filter:brightness(0.60);">
    <div class="section-head pb-5">KiTS Core</div>
    <div class="row pb-5">
      <ng-container *ngFor='let post of posts'>
        <div *ngIf='post.content_category=="KiTS Core"' class="col-lg-3 col-sm-3 col-md-3 px-lg-5">
          <img src="https://kits.api-central.kenmarkserver.com/about/{{post.thumbnail}}" class="pt-3">
          <div class="section-body pt-4 pb-1">{{post.name}}</div>
          <div class="section-body postTitle pb-4" style="color: #77a1db;">{{post.title}}</div>
          <div class="section-body" style="color: #707070;">{{post.location}} </div>
        </div>
      </ng-container>
    </div>
  </div> -->
  <!--veteran team-->
  <!-- <div class="text-center container-fluid p-lg-5 p-4 p-sm-4"
    style="background-image: url(./../../../assets/images/bg-light.png);backdrop-filter:brightness(0.30);">
    <div class="section-head pb-5">KiTS Veterans</div>
    <div class="row pb-5">
      <ng-container *ngFor='let post of posts'>
        <div *ngIf='post.content_category=="KiTS Veterans"' class="col-lg-3 col-sm-3 col-md-3 px-lg-5">
          <img src="https://kits.api-central.kenmarkserver.com/about/{{post.thumbnail}}" class="pt-3">
          <div class="section-body pt-4 pb-1">{{post.name}}</div>
          <div class="section-body postTitle pb-4" style="color: #77a1db;">{{post.title}}</div>
          <div class="section-body" style="color: #707070;">{{post.location}} </div>
        </div>
      </ng-container>
    </div>
  </div> -->