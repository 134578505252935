import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientsService extends DataService {
  constructor(http: HttpClient) {
    super("https://kits.api-central.kenmarkserver.com/home", http);
  }
}
