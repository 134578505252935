import { Component } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent{
  constructor() { }

  email = new FormControl('', [Validators.required, Validators.email]);
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Please enter email';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  sendForm(form: NgForm){
    if (form.invalid) {
      return;
    }
    let data = {
      fname: form.value.fname,
      lname: form.value.lname,
      email: this.email.value,
      number: form.value.number,
      company: form.value.company,
    }
    // this.post = this.service.postAll(data)
    //   .subscribe(res => {
    //     console.log(res);
    //     this.message = "Contact From submitted!";
    //     this._snackBar.open(this.message, 'Dismiss', {
    //       duration: 2500,
    //     });
    //   },
    //     (error) => {
    //       this.errorMessage = error;
    //       this.error = true;
    //     }
    //   );
  }
}
