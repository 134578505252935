<div class="container-fluid">
    <div class="row p-5 font-weight-lighter bg-light-img text-center">
        <div class="col p-lg-5">
            <p class="heading">Web Hosting Services,</p>
            <p class="subheading">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore
                magna aliqua. Bibendum est ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris vitae
                ultricies
                leo
                integer malesuada. Ac odio tempor orci dapibus ultrices in. Egestas diam in arcu cursus euismod. Dictum
                fusce
                ut placerat orci nulla. Tincidunt ornare massa eget
            </p>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 19.png" class="img-fluid" alt="web_img">
            <p class='pt-5 serviceName'>Shared</p>
        </div>

        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 20.png" class="img-fluid" alt="development_img">
            <p class="pt-5 serviceName">Wordpress</p>
        </div>

        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 18.png" class="img-fluid" alt="designing_img">
            <p class="pt-5 serviceName">Moodle</p>
        </div>

        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 17.png" class="img-fluid" alt="branding_img">
            <p class='pt-5 serviceName'>Cloud Storage</p>
        </div>

        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 21.png" class="img-fluid" alt="marketing_img">
            <p class="pt-5 serviceName">VPS</p>
        </div>

        <div class="col-lg col-6 body text-center services">
            <img src="../../../assets/images/icons/Mask Group 22.png" class="img-fluid" alt="consultancy_img">
            <p class='pt-5 serviceName'>Dedicated</p>
        </div>
    </div> -->
</div>