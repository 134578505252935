import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  panelOpenState = false;
  proDetails: boolean;
  constructor() { }
  ngOnInit(): void {
  }
  showProDetails(){
    this.proDetails = this.proDetails ? false : true;
  }
}
