import { HostingService } from './../../../services/hosting.service';
import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { HostingCustomService } from 'src/app/services/hosting-custom.service';

@Component({
  selector: 'app-vps-custom',
  templateUrl: './vps-custom.component.html',
  styleUrls: ['./vps-custom.component.scss'],
})
export class VpsCustomComponent implements OnInit {
  backup = 0; os = 0; ssl = 0; cPanel = 0; ip = 0;
  posts: any;
  osId: number = 1;
  ipId: number = 11;
  cPanelId: number = 13;
  backupId: number;
  sslId: number;
  pack: any;
  customPack: any;
  osNameDisplay: any;
  ipAddressDisplay:any;
  cpanelDisplay:any;
  backupSpace:any;
  constructor(private service: HostingCustomService, private sharing: HostingService) { }

  ngOnInit() {
    this.service.getAll("1").subscribe(post => {
      this.posts = post;
      this.osNameDisplay = this.posts['productCust'][0].optionName;
      this.ipAddressDisplay = this.posts['productCust'][10].optionName;
      this.cpanelDisplay = this.posts['productCust'][12].optionName;
    });
    this.sharing.currentPack.subscribe(message => this.pack = message);
  }



  osCost(cost, id, osName) {
    this.osNameDisplay = [];
    this.os = cost;
    this.osId = id;
    this.osNameDisplay = osName;
  }

  ipCost(cost, id,ipAddress) {
    this.ipAddressDisplay = [];
    this.ip = cost;
    this.ipId = id;
    this.ipAddressDisplay = ipAddress;
  }
  cPanelCost(cost, id,panel) {
    this.cpanelDisplay = [];
    this.cPanel = cost;
    this.cPanelId = id;
    this.cpanelDisplay = panel;
  }
  sslCost(cost, id) {
    this.ssl = cost;
    this.sslId = id;
  }
  backupCost(cost, id,backup) {
    this.backupSpace = [];
    this.backup = cost;
    this.backupId = id;
    this.backupSpace = backup;
  }
  toCart(total) {
    console.log('Total:', total)
  }
}
  // using array
  // getCost(cost) {
  // document.body.style.color ='#369bd6';
  // this.costs.push(cost); 
  // this.total = this.costs.reduce((a, b) => a + b, 0);
  // console.log(cost);
  // }