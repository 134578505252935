<div style="background-color: #eee;">
    <div class="container-fluid costumPaddingDetails">
        <div class="row">
            <div class="col-3">
                <p class="m-0 packageType">VPS-Bronze</p>
            </div>
            <div class="col-3 d-flex align-items-center">
                <p class="m-0 productDetails dropdown-toggle" (click)="showProDetails()">Show product details</p>
            </div>
            <div class="col-3 text-right">
                <p class="m-0 quantity"><span>-</span>&nbsp;&nbsp;&nbsp;<span style="color: #707070;">1</span>&nbsp;&nbsp;&nbsp;<span>+</span></p>
            </div>
            <div class="col-3 text-right">
                <p class="m-0 price">₹3,899.00</p>
            </div>
        </div>
        <div class="row pt-5" *ngIf="proDetails">
            <div class="col-6 text-center">
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
            </div>
            <div class="col-6 text-center">
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
                <p class="info_text">• 4 cores, 8GB RAM, 200GB SSD, 200Mbit/s, Unlimited*</p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid costumPaddingPricing">
    <div class="row m-0">
        <div class="col-6">
            <p class="billdetails">Subtotal</p>
        </div>
        <div class="col-6 text-right">
            <p class="billdetails">₹3,899.00</p>
        </div>
        <div class="col-6">
            <p class="billdetails">Shipping</p>
        </div>
        <div class="col-6 text-right">
            <p class="billdetails">0</p>
        </div>
        <div class="col-6">
            <p class="billdetails">Tax</p>
        </div>
        <div class="col-6 text-right">
            <p class="billdetails">0</p>
        </div>
        <div class="col-6 pt-5">
            <p class="totalText">Total</p>
        </div>
        <div class="col-6 text-right pt-5">
            <p class="totalText">₹3,899.00</p>
        </div>
    </div>
    <div class="row m-0">
        <div class="col">
            <p class="paymentText">Payment Method</p>
        </div>
        <div class="col text-right">
            <mat-radio-group>
                <mat-radio-button value="1" class="px-lg-4">PayPal</mat-radio-button>
                <mat-radio-button value="2" class="pl-2">RazorPay</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-3 billing">
            <p class="billingType">Billed Monthly</p>
            <p class="billingRate">₹3,899/Mo</p>
            <p class="discount">You save<br>
                ₹0/Mo
            </p>
            <mat-radio-group name="single_radio">
                <mat-radio-button value="1" class="pb-4"></mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-3 billing">
            <p class="billingType">Billed every 3 months</p>
            <p class="billingRate">₹3,699/Mo</p>
            <p class="discount">You save<br>
                ₹200/Mo
            </p>
            <mat-radio-group name="single_radio">
                <mat-radio-button value="2" class="pb-4"></mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-3 billing">
            <p class="billingType">Billed every 6 months</p>
            <p class="billingRate">₹3,499/Mo</p>
            <p class="discount">You save<br>
                ₹400/Mo
            </p>
            <mat-radio-group name="single_radio">
                <mat-radio-button value="3" class="pb-4"></mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-3 billing">
            <p class="billingType">Billed annually</p>
            <p class="billingRate">₹3,299/Mo</p>
            <p class="discount">You save<br>
                ₹600/Mo
            </p>
            <mat-radio-group name="single_radio">
                <mat-radio-button value="4" class="pb-4"></mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="col-12 p-4 d-flex align-items-center justify-content-center" style="background-color: #77a1db;">
            <span class="paynowPrice">Make Payment</span>
        </div>
    </div>
</div>