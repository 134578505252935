import { LocalCacheService } from './../../services/local-cache.service';
import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private service: AuthService, private route: Router, private cache: LocalCacheService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // if (this.cache.getItem('token')) {
    //   return true;
    // } else {
    if (this.service.isLoggedIn == false) {
      this.route.navigateByUrl('/login');
      return false;
    }
    else {
      return true;
    }
    // }
  }
}
