<div class="container-fluid text-center p-lg-5">
    <div class="section-head font-weight-normal py-3">Choose your Dedicated Pack</div>
    <div *ngIf="posts" class="row packages">
        <ng-container *ngFor="let post of posts.product">
            <div class="col-lg col-sm-12 m-lg-1 mx-lg-0 mx-5 py-lg-5 py-2">
                <div class="package-content mb-lg-4 py-5">
                    <p>{{post.priceINR | currency :'INR'}}/Mo</p>
                    <p>{{post.processor}}</p>
                    <p>{{post.memory}}</p>
                    <p>{{post.baseStorage}} SSD</p>
                </div>
                <div class="package-cost px-2 mb-5">
                    <abbr>{{post.name}}</abbr>
                    <p class="p-lg-3 p-md-3">Starting at {{post.priceINR | currency:'INR'}}/Mo</p>
                    <a [routerLink]="['custom']" (click)="sendPack(post)" class="ken-btn p-2 text-white px-4">
                        Configure
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>