import { Component, OnInit } from '@angular/core';
import { HostingService } from 'src/app/services/hosting.service';

@Component({
  selector: 'app-web-hosting',
  templateUrl: './web-hosting.component.html',
  styleUrls: ['./web-hosting.component.scss']
})
export class WebHostingComponent implements OnInit {
  posts: any;
  type: string = "3"; //change service route of hosting service
  message: any;
  constructor(private service: HostingService) { }

  ngOnInit(): void {
    this.service.getAll(this.type)
      .subscribe(post => this.posts = post)
    this.service.currentPack.subscribe(message => this.message = message)
  }
  sendPack(post) {
    this.service.sendData(post)
  }
}
