import { CartComponent } from './components/cart/cart.component';
import { WebHostingCustomComponent } from './components/hostingCustom/web-hosting-custom/web-hosting-custom.component';
import { CustomGuard } from './guard/hosting/custom.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from "./guard/admin/admin.guard";

import { OrdersComponent } from './components/orders/orders.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/company/about/about.component';

import { VpsComponent } from './components/hosting/vps/vps.component';
import { DedicatedComponent } from './components/hosting/dedicated/dedicated.component';
import { CloudStorageComponent } from './components/hosting/cloud-storage/cloud-storage.component';
import { WebHostingComponent } from './components/hosting/web-hosting/web-hosting.component';

import { CloudCustomComponent } from './components/hostingCustom/cloud-custom/cloud-custom.component';
import { VpsCustomComponent } from './components/hostingCustom/vps-custom/vps-custom.component';
import { DedicatedCustomComponent } from './components/hostingCustom/dedicated-custom/dedicated-custom.component';
import { ContactDetailsComponent } from './components/contact/contact-details/contact-details.component';
import { ContactFormComponent } from './components/contact/contact-form/contact-form.component';
import { OurWorkComponent } from './components/company/our-work/our-work.component';
import { OurTeamComponent } from './components/company/our-team/our-team.component';
import { HostingComponent } from './components/hosting/hosting/hosting.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { DevelopmentComponent } from './components/development/development.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
const routes: Routes = [
  {
    path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent,
  },
  {
    path: 'contact-details', component: ContactDetailsComponent,
  },
  {
    path: 'contact-form', component: ContactFormComponent,
  },
  {
    path: 'our-work', component: OurWorkComponent,
  },
  {
    path: 'our-team', component: OurTeamComponent,
  },
  {
    path: 'about', component: AboutComponent,
  },
  {
    path: 'login', component: LoginComponent,
  },
  {
    path: 'signUp', component: SignupComponent,
  },  
  {
    path: 'hosting', component: HostingComponent,
  },
  {
    path: 'policies', component: PoliciesComponent,
  },  
  {
    path: 'development', component: DevelopmentComponent,
  },
  {
    path: 'checkout', component: CheckoutComponent,
  },
  {
    path: 'cart', component: CartComponent,
    // canActivate: [AdminGuard]
  },
  {
    path: 'orders', component: OrdersComponent,
    canActivate: [AdminGuard]
  },
  {
    path: 'hosting/vps/custom', component: VpsCustomComponent,
    canActivate: [CustomGuard]
  },
  {
    path: 'hosting/dedicated/custom', component: DedicatedCustomComponent,
    canActivate: [CustomGuard]
  },
  {
    path: 'hosting/shared/custom', component: VpsComponent,
    canActivate: [CustomGuard]
  },
  {
    path: 'hosting/web-hosting/custom', component: WebHostingCustomComponent,
    canActivate: [CustomGuard]
  },
  {
    path: 'hosting/vps', component: VpsComponent,
  },
  {
    path: 'hosting/cloudStorage', component: CloudStorageComponent,
  },
  {
    path: 'hosting/dedicated', component: DedicatedComponent,
  },
  {
    path: 'hosting/web-hosting', component: WebHostingComponent,
  },
  { path: "*", redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }