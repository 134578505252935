<div class="container-fluid parentDiv">
	<div class="row">
		<div class="col-lg-3 col-sm-12 col-12 text-lg-left text-center pt-5 pl-lg-5 pack-details">
			<div class="sticky pl-lg-2 pt-lg-5">
				<p>{{((pack.priceINR)+os+backup+cPanel+ip+ssl | currency:'INR')}}/Mo </p>
				<p>{{pack.processor}}</p>
				<p>{{pack.maxMemory}}</p>
				<p>{{pack.baseStorage}}</p>
				<p>{{pack.network}}</p>
				<p>{{pack.bandwidth}}</p>
				<div class="package-cost-custom pb-5 pt-5">
					<abbr>{{pack.name}}</abbr>
					<p>Base Prize {{pack.priceINR|currency:'INR'}}/Mo</p>
				</div>
			</div>
		</div>
		<div *ngIf='posts' class="p-lg-5 col-lg-9 col-sm-12 custom-detail">
			<div class="p-lg-5 p-sm-4 text-lg-left text-center">
				<div>
					<div class="section-head-small mt-4">Customize your VPS</div>
					<p>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod
						Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Bibendum Est Ultricies
						Integer Quis. Iaculis Urna Id Volutpat Lacus Laoreet. Mauris Vitae Ultricies
						Leo Integer Malesuada. Ac Odio Tempor Orci Dapibus</p>
					<hr class="dark mt-lg-5">
					<!--operating system custom-->
					<h4 class="py-4 font-weight-bold">Operating Systems</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Operating System</div>
						<div class="col">Access Type</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Operating System'"
							(click)="osCost(post.optionPricePerMo,post.optionName)">
							<div [ngClass]="osName==post.optionName? 'active':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.optionName}}</div>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo=='0'" class="col-lg col-4 col-md-4 col-sm-4 p-4">
										Free</div>
									<div *ngIf="post.optionPricePerMo!='0'" class="col-lg col-4 col-md-4 col-sm-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">You can request for an OS swap even after you have setup
						your
						server. This is
						possible only between the FREE Operating Systems.
					</div>
					<!--ip custom-->
					<hr class="dark">
					<h4 class="py-4 font-weight-bold p-2">IP Address</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">IPv4</div>
						<div class="col">IPv6</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='IP Address'"
							(click)="ipCost(post.optionPricePerMo,post.optionName)">
							<div [ngClass]="ipName==post.optionName? 'active':''" class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.optionName}}</div>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo=='0'" class="col-lg col-4 col-sm-4 col-md-4 p-4">
										Free</div>
									<div *ngIf="post.optionPricePerMo!='0'" class="col-lg col-4 col-sm-4 col-md-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">You can purchase up-to 20 IPv4 Addresses for your
						server.
					</div>
					<hr class="dark mt-5">
					<!--Control Panel-->
					<h4 class="py-4 font-weight-bold p-2">Control Panel</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Product</div>
						<div class="col">Compatibility</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Control Panel'"
							(click)="cPanelCost(post.optionPricePerMo,post.optionName)">
							<div [ngClass]="cPanelName==post.optionName? 'active':''"
								class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										{{post.optionName}}</div>
									<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo=='0'"
										class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">Free</div>
									<div *ngIf="post.optionPricePerMo!='0'"
										class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">All servers are provided as root access without any
						pre-installed software. You can opt in if you like
					</div>
					<hr class="dark mt-5">
					<!--Backup Space-->
					<h4 class="py-4 font-weight-bold p-2">Backup Space</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Storage</div>
						<div class="col">Type</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='Backup Space'"
							(click)="backupCost(post.optionPricePerMo,post.optionName)">
							<div [ngClass]="backupName==post.optionName? 'active':''"
								class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.optionName}}</div>
									<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.additionalAttribute1}}</div>
									<div *ngIf="post.optionPricePerMo=='0'" class="col-lg col-4 col-sm-4 col-md-4 p-4">
										Free</div>
									<div *ngIf="post.optionPricePerMo!='0'" class="col-lg col-4 col-sm-4 col-md-4 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
							</div>
						</a>
					</div>
					<hr class="dark mt-5">
					<!--SSL Certificates-->
					<h4 class="py-4 font-weight-bold p-2">SSL Certificates</h4>
					<div class="row text-center font-weight-bold">
						<div class="col">Single Drive Parity</div>
						<div class="col">Validation Type </div>
						<div class="col">Drives</div>
						<div class="col">Price</div>
					</div>
					<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
						<a *ngIf="post.optionCategory=='SSL'"
							(click)="sslCost(post.optionPricePerMo,post.additionalAttribute2)">
							<div [ngClass]="sslName==post.additionalAttribute2? 'active':''"
								class="row text-center my-3 option">
								<ng-container>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.optionName}}</div>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute1}}
									</div>
									<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute2}}
									</div>
									<div *ngIf="post.optionPricePerMo=='0'" class="col-lg col-3 col-md-3 col-sm-3 p-4">
										Free</div>
									<div *ngIf="post.optionPricePerMo!='0'" class="col-lg col-3 col-md-3 col-sm-3 p-4">
										₹{{post.optionPricePerMo}}/Mo
									</div>
								</ng-container>
							</div>
						</a>
					</div>
					<div class="col text-secondary option-text">Organization and Extended Validation available on
						request
					</div>
					<div class="col text-secondary option-text">Get well known certificates to use with your apps and
						websites
						to secure them with
						HTTPS protocol.
					</div>
					<div class="col text-secondary option-text">Setup Cost: Rs. 999 - One Time Fee | Minimum Order: 1
						Months
						Contract | SLA: 99.5%
					</div>
				</div>
				<hr class="dark mt-5">
				<!--FAQs-->
				<div class="pt-4 font-weight-bolder px-2 faq-head">FAQ</div>
				<h5 class="font-weight-bold pb-2">(Must read before purchasing)</h5>
				<div class="faq-question pt-3">1. Why choose Kenmark ITan Solutions as your provider for Virtual
					Private
					Servers?</div>
				<div class="faq-ans">Answer: Simply because you can get a good bang for you buck with a high
					specification
					and reliability. We use only the latest tech for our top-selling servers.</div>
				<div class="faq-question pt-3">2. Do your servers really come with Unlimited Bandwidth?*</div>
				<div class="faq-ans">Answer: We provide unlimited bandwidth, both inwards and outwards. If average
					traffic
					consumption continuously exceeds 100Mbit/s during a time span of 7 days, the
					connection is switched to 100Mbit/s.</div>
				<div class="faq-question pt-3">2. Do your servers really come with Unlimited Bandwidth?*</div>
				<div class="faq-ans">Answer: Unfortunately we don’t support this right now. You may want to select
					something from our wide Operating System offerings.</div>
				<div class="faq-question pt-3">4. I want to re-install my Operating System, how do I go about this?
				</div>
				<div class="faq-ans">Answer: Please write us an email at itan@kenmark.in with the request and this
					will
					be done within 2 hours. Kindly take a backup before you send the request,
				</div>
				<br><br>
			</div>
		</div>
	</div>
	<div class="row amount p-lg-4 justify-content-center p-3 p-md-4">
		<div class="text-center ml-lg-auto mr-lg-4 text-lg-right">
			<div class="option-cost text-center px-4">
				{{((pack.priceINR)+os+backup+cPanel+ip+ssl | currency:'INR') || '0'}}
				<a routerLink="/cart" class="ken-btn p-2 p-lg-2 px-2 px-lg-4 mx-lg-2 text-white">
					Continue
				</a>
			</div>
		</div>
	</div>
</div>