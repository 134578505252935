<!--===========================FOOTER CODE===============================================================-->
<div class="container-fluid" style="background-color:black">

  <div class="row pt-lg-3 justify-content-around">
    <div class="col-lg-2 p-3">
      <ul class="list-unstyled text-white">
        <li class="font-weight-bold pb-2" routerLink='/contact'><a class="text-white">CONTACT US</a></li>
        <li href="tel:+91-9820283097" class="mb-2"><a>+91-9820283097</a></li>
        <li class="mb-2"><a href='mailto:itan@kenmark.in'>itan@kenmark.in</a> </li>
        <li><a>601-604,Chaitanya CHS LTD, <br>Near Ram Mandir Signal, Goregaon West,<br> Mumbai - 400104.</a></li>
      </ul>
    </div>
    <div class="col-lg-2 p-3">
      <ul class="list-unstyled text-white">
        <li class="font-weight-bold pb-2"><a class="text-white">Company</a></li>
        <li class="mb-2"><a>About Us</a></li>
        <li class="mb-2"><a>Courses</a></li>
        <li class="mb-2"><a routerLink="/policies">Privacy Policy</a></li>
        <li class="mb-2"><a routerLink="/policies">Refund Policy</a></li>
        <li class="mb-2"><a routerLink="/policies">Terms & Conditions</a></li>
      </ul>
    </div>
    <div class="col-lg-2 p-3">
      <ul class="list-unstyled">
        <li class="font-weight-bold pb-2"><a class="text-white">KiTS+</a></li>
        <li class="mb-2"><a>Careers</a></li>
        <li class="mb-2"><a>Summer Internship Program</a></li>
        <li class="mb-2"><a>Partner with us</a></li>
        <li class="mb-2"><a>KiTS Classroom</a></li>
        <li class="mb-2"><a>KiTS Hackathon</a></li>
      </ul>
    </div>
    <div class="col-lg-2 p-3">
      <ul class="list-unstyled">
        <li class="font-weight-bold pb-2"><a class="text-white">Sevices</a></li>
        <li class="mb-2"><a>Web Hosting</a></li>
        <li class="mb-2"><a>Development</a></li>
        <li class="mb-2"><a>Branding & Design</a></li>
        <li class="mb-2"><a>Marketing</a></li>
        <li class="mb-2"><a>Consultancy</a></li>
      </ul>
    </div>
    <div class="col-lg-2 p-3 text-lg-right">
      <ul class="list-unstyled">
        <li class="font-weight-bold pb-2">
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Twitter – 6.png" alt="Twitter"></a>
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Facebook – 6.png" alt="Facebook"></a>
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Instagram – 6.png" alt="Instagram"></a>
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 16.png" alt="LinkdIn"></a>
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 13.png" alt="Mail"></a>
          <a style="padding: 0px 10px;"><img src="assets/images/icons/Group 15.png" alt="Phone"></a>
        </li>
        <li>
          <a routerLink='/home'>
            <img src="assets/images/4.png" class="align img-fluid" alt="footerLogo">
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>