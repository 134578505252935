<nav class="navbar sticky-top navbar-expand-lg bg-white py-0">
  <a class="navbar-brand pl-lg-2" routerLink='/home'>
    <img class="img-fluid" src="../../assets/images/7.png" alt="Kenmark Logo">
  </a>
  <button class="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#navbarContent"
    aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation" style="outline: none;">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarContent">
    <ul class="navbar-nav font-weight-bold ml-auto">
      <li class="nav-item">
        <a class="nav-link text-lg-center" routerLink='/home' data-target=".navbar-collapse.show"
          [routerLinkActive]="['active']"  (click)="shownone()" data-toggle="collapse">HOME</a>
      </li>

      <li class="nav-item">
        <a class="nav-link text-lg-center" data-toggle="collapse"
          [ngClass]="{'active': isActiveCompany()===true? '#77a1db' : '' }" data-target="#CompanyNav" role="button"
          aria-expanded="false" aria-controls="hostingNav" routerLink='/about' (click)="showcompany()" [routerLinkActive]="['active']">COMPANY</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-lg-center" data-toggle="collapse"
          [ngClass]="{'active': isActive()===true? '#77a1db' : '' }" data-target="#hostingNav" role="button"
          aria-expanded="false" aria-controls="hostingNav" routerLink='/hosting' (click)="showhosting()" [routerLinkActive]="['active']">HOSTING</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-lg-center" routerLink='/development' data-target=".navbar-collapse.show"
          [routerLinkActive]="['active']"  (click)="shownone()" data-toggle="collapse">DEVELOPMENT</a>
      </li>
      <li class="nav-item">
        <a class="nav-link text-lg-center" data-toggle="collapse"
          [ngClass]="{'active': isActiveContact()===true? '#77a1db' : '' }" data-target="#ContactNav" role="button"
          aria-expanded="false"  (click)="showcontact()" aria-controls="hostingNav" routerLink='/contact-details'>CONTACT</a>
      </li>
      <li class="nav-item px-3 mt-2 pt-lg-4">
        <a routerLink='/login' data-toggle="collapse" data-target=".navbar-collapse.show">
          <i class="material-icons" [routerLinkActive]="['active']"  (click)="shownone()" style="font-size:18px;">
            person
          </i>
        </a>
      </li>
      <li class="nav-item px-3 mt-2 pt-lg-4">
        <a routerLink='/cart' data-toggle="collapse" data-target=".navbar-collapse.show">
          <i class="material-icons" [routerLinkActive]="['active']"  (click)="shownone()" style="font-size:18px;">
            shopping_cart
          </i>
        </a>
      </li>
    </ul>
  </div>
</nav>
<!----------------------------------hosting------------------------------------->
<div id="hostingNav" data-target=".navbar-collapse.show" data-toggle="collapse"
  class="category collapse container-fluid">
  <div class="row text-white text-center p-lg-4 px-lg-5 p-sm-1">
    <a routerLink='/hosting/web-hosting' [routerLinkActive]="['active']" class="col-lg col-4 col-md p-1 link">Web
      Hosting</a>
    <a routerLink='/hosting/wordpress' [routerLinkActive]="['active']" class="col-lg col-4 col-md p-1 link">Game
      Servers</a>
    <a routerLink='/hosting/vps' [routerLinkActive]="['active']" class="col-lg col-4 col-md p-1 link">VPS</a>
    <a routerLink='/hosting/cloudStorage' [routerLinkActive]="['active']" class="col-lg col-6 col-md p-1 link">Cloud
      Storage</a>
    <a routerLink='/hosting/dedicated' [routerLinkActive]="['active']"
      class="col-lg col-6 col-md p-1 link">Dedicated</a>
  </div>
</div>
<!----------------------------------hosting------------------------------------->

<!----------------------------------Contact------------------------------------->
<div id="ContactNav" data-target=".navbar-collapse.show" data-toggle="collapse"
  class="category collapse container-fluid">
  <div class="row justify-content-center text-center p-lg-4 px-lg-5 p-sm-1">
    <a routerLink='/contact-details' [routerLinkActive]="['active']" class="col-lg-3 col-4 col-md-3 p-1 link">Contact Details</a>
    <a routerLink='/contact-form' [routerLinkActive]="['active']" class="col-lg-3 col-4 col-md-3 p-1 link">Contact Form</a>
  </div>
</div>
<!----------------------------------Contact------------------------------------->

<!----------------------------------Company------------------------------------->
<div id="CompanyNav" data-target=".navbar-collapse.show" data-toggle="collapse"
  class="category collapse container-fluid">
  <div class="row justify-content-center text-center p-lg-4 px-lg-5 p-sm-1">
    <a routerLink='/about' [routerLinkActive]="['active']" class="col-lg-3 col-4 col-md-3 p-1 link">About</a>
    <a routerLink='/our-work' [routerLinkActive]="['active']" class="col-lg-3 col-4 col-md-3 p-1 link">Our Work</a>
    <a routerLink='/our-team' [routerLinkActive]="['active']" class="col-lg-3 col-4 col-md-3 p-1 link">Our Team</a>
  </div>
</div>
<!----------------------------------Company------------------------------------->