<div class="container-fluid parentDiv">
	<div class="row">
		<div class="col-lg-3 col-sm-12 col-12 text-lg-left text-center pt-5 py-auto pl-lg-5 pack-details">
			<div class="sticky pl-lg-2 pt-lg-5">
				<p>{{((pack.priceINR)+os+backup+cPanel+ip+ssl | currency:'INR')}}/Mo </p>
				<p>{{pack.processor}}</p>
				<p>{{pack.maxMemory}}</p>
				<p>{{pack.baseStorage}}</p>
				<p>{{pack.network}}</p>
				<p>{{pack.bandwidth}}</p>
				<div class="package-cost-custom pb-3 pt-3">
					<abbr>{{pack.name}}</abbr>
					<p>Starting at {{pack.priceINR|currency:'INR'}}</p>
				</div>
				<div class="package-cost-custom pt-3" *ngIf="osNameDisplay">
					<abbr>Operating System</abbr>
					<p>{{osNameDisplay}}</p>
				</div>

				<div class="package-cost-custom pt-3" *ngIf="ipAddressDisplay">
					<abbr>IP Address</abbr>
					<p>{{ipAddressDisplay}}</p>
				</div>

				<div class="package-cost-custom pt-3" *ngIf="cpanelDisplay">
					<abbr>Control Panel</abbr>
					<p>{{cpanelDisplay}}</p>
				</div>

				<div class="package-cost-custom pt-3" *ngIf="backupSpace">
					<abbr>Backup Space</abbr>
					<p>{{backupSpace}}</p>
				</div>
			</div>
		</div>
		<div *ngIf='posts' class="p-lg-5 col-lg-9 col-sm-12 custom-detail">
			<div class="p-lg-5 text-lg-left text-center">
				<div class="section-head-small">Customize your VPS</div>
				<p>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit, Sed Do Eiusmod
					Tempor Incididunt Ut Labore Et Dolore Magna Aliqua. Bibendum Est Ultricies
					Integer Quis. Iaculis Urna Id Volutpat Lacus Laoreet. Mauris Vitae Ultricies
					Leo Integer Malesuada. Ac Odio Tempor Orci Dapibus</p>
				<hr class="dark mt-lg-5">
				<!--operating system custom-->
				<h4 class="py-4 font-weight-bold">Operating Systems</h4>
				<div class="row text-center font-weight-bold">
					<div class="col">Operating System</div>
					<div class="col">Access Type</div>
					<div class="col">Price</div>
				</div>
				<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
					<a *ngIf="post.optionCategory=='Operating System'"
						(click)="osCost(post.optionPricePerMo,post.id,post.optionName)">
						<div [ngClass]="osId==post.id? 'active-pack':''" class="row text-center my-3 option">
							<ng-container>
								<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.optionName}}</div>
								<div class="col-lg col-4 col-md-4 col-sm-4 p-4">{{post.additionalAttribute1}}</div>
								<div *ngIf="(post.optionPricePerMo > 0) else zero;"
									class="col-lg col-4 col-md-4 col-sm-4 p-4">₹{{post.optionPricePerMo}}/Mo
								</div>
							</ng-container>
							<ng-template #zero>
								<div *ngIf="(post.id==1 && osId==1) else free"
									class="col-lg col-4 col-md-4 col-sm-4 p-4">
									Included</div>
								<ng-template #free>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
								</ng-template>
							</ng-template>
						</div>
					</a>
				</div>
				<div class="col text-secondary option-text">You can request for an OS swap even after you have setup
					your
					server. This is
					possible only between the FREE Operating Systems.
				</div>
				<!--ip custom-->
				<hr class="dark">
				<h4 class="py-4 font-weight-bold p-2">IP Address</h4>
				<div class="row text-center font-weight-bold">
					<div class="col">IPv4</div>
					<div class="col">IPv6</div>
					<div class="col">Price</div>
				</div>
				<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
					<a *ngIf="post.optionCategory=='IP Address'" (click)="ipCost(post.optionPricePerMo,post.id,post.optionName)">
						<div [ngClass]="ipId==post.id? 'active-pack':''" class="row text-center my-3 option">
							<ng-container>
								<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.optionName}}</div>
								<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.additionalAttribute1}}</div>
								<div *ngIf="post.optionPricePerMo>0 else zero"
									class="col-lg col-4 col-sm-4 col-md-4 p-4">
									₹{{post.optionPricePerMo}}/Mo
								</div>
							</ng-container>
							<ng-template #zero>
								<div *ngIf="post.id==11 && ipId==11 else free"
									class="col-lg col-4 col-md-4 col-sm-4 p-4">
									Included</div>
								<ng-template #free>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
								</ng-template>
							</ng-template>
						</div>
					</a>
				</div>
				<div class="col text-secondary option-text">You can purchase up-to 20 IPv4 Addresses for your
					server.
				</div>
				<hr class="dark mt-5">
				<!--Control Panel-->
				<h4 class="py-4 font-weight-bold p-2">Control Panel</h4>
				<div class="row text-center font-weight-bold">
					<div class="col">Product</div>
					<div class="col">Compatibility</div>
					<div class="col">Price</div>
				</div>
				<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
					<a *ngIf="post.optionCategory=='Control Panel'" (click)="cPanelCost(post.optionPricePerMo,post.id,post.optionName)">
						<div [ngClass]="cPanelId==post.id? 'active-pack':''" class="row text-center my-3 option">
							<ng-container>
								<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
									{{post.optionName}}</div>
								<div class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
									{{post.additionalAttribute1}}</div>
								<div *ngIf="post.optionPricePerMo>0 else zero"
									class="col-lg col-4 col-sm-4 col-md-4-lg col-4 col-sm-4 col-md-4 p-4">
									₹{{post.optionPricePerMo}}/Mo
								</div>
							</ng-container>
							<ng-template #zero>
								<div *ngIf="(post.id==13 && cPanelId==13) else free"
									class="col-lg col-4 col-md-4 col-sm-4 p-4">
									Included</div>
								<ng-template #free>
									<div class="col-lg col-4 col-md-4 col-sm-4 p-4">Free</div>
								</ng-template>
							</ng-template>
						</div>
					</a>
				</div>
				<div class="col text-secondary option-text">All servers are provided as root access without any
					pre-installed software. You can opt in if you like
				</div>
				<hr class="dark mt-5">
				<!--Backup Space-->
				<h4 class="py-4 font-weight-bold p-2">Backup Space</h4>
				<div class="row text-center font-weight-bold">
					<div class="col">Storage</div>
					<div class="col">Type</div>
					<div class="col">Price</div>
				</div>
				<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
					<a *ngIf="post.optionCategory=='Backup Space'" (click)="backupCost(post.optionPricePerMo,post.id,post.optionName)">
						<div [ngClass]="backupId==post.id? 'active-pack':''" class="row text-center my-3 option">
							<ng-container>
								<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.optionName}}</div>
								<div class="col-lg col-4 col-sm-4 col-md-4 p-4">{{post.additionalAttribute1}}</div>
								<div class="col-lg col-4 col-sm-4 col-md-4 p-4">
									₹{{post.optionPricePerMo}}/Mo
								</div>
							</ng-container>
						</div>
					</a>
				</div>
				<div class="col text-secondary option-text">Expensive RAID 6 backup storage which can be accessed over
					FTPS from the internal network. Bandwidth is unlimited between your servers and backups
				</div>
				<hr class="dark mt-5">
				<!--SSL Certificates-->
				<h4 class="py-4 font-weight-bold p-2">SSL Certificates</h4>
				<div class="row text-center font-weight-bold">
					<div class="col">Single Drive Parity</div>
					<div class="col">Validation Type </div>
					<div class="col">Drives</div>
					<div class="col">Price</div>
				</div>
				<div class="px-2 px-lg-0" *ngFor="let post of posts.productCust">
					<a *ngIf="post.optionCategory=='SSL'" (click)="sslCost(post.optionPricePerYr,post.id)">
						<div [ngClass]="sslId==post.id? 'active-pack':''" class="row text-center my-3 option">
							<ng-container>
								<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.optionName}}</div>
								<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute1}}
								</div>
								<div class="col-lg col-3 col-md-3 col-sm-3 p-4">{{post.additionalAttribute2}}
								</div>
								<div class="col-lg col-3 col-md-3 col-sm-3 p-4">
									₹{{post.optionPricePerYr}}/Yr
								</div>
							</ng-container>
						</div>
					</a>
				</div>
				<div class="col text-secondary option-text">Organization and Extended Validation available on
					request
				</div>
				<div class="col text-secondary option-text">Get well known certificates to use with your apps and
					websites
					to secure them with
					HTTPS protocol.
				</div>
				<div class="col text-secondary option-text">Setup Cost: Rs. 999 - One Time Fee | Minimum Order: 1
					Months
					Contract | SLA: 99.5%
				</div>
				<hr class="dark mt-5">
				<h4 class="pt-4 font-weight-bold p-2">Notes?</h4>
				<mat-form-field appearance="outline" class="px-lg-0 px-5 w-100">
					<mat-label>Details</mat-label>
					<textarea matInput placeholder="More customization"></textarea>
				</mat-form-field>
				<hr class="dark">
				<!--FAQs-->
				<div class="pt-4 font-weight-bolder px-2 faq-head">FAQ</div>
				<h5 class="font-weight-bold pb-2">(Must read before purchasing)</h5>
				<div class="faq-question pt-3">1. Why choose Kenmark ITan Solutions as your provider for Virtual
					Private
					Servers?</div>
				<div class="faq-ans">Answer: Simply because you can get a good bang for you buck with a high
					specification
					and reliability. We use only the latest tech for our top-selling servers.</div>

				<div class="faq-question pt-4">2. Do your servers really come with Unlimited Bandwidth?*</div>
				<div class="faq-ans">Answer: We provide unlimited bandwidth, both inwards and outwards. If average
					traffic
					consumption continuously exceeds 100Mbit/s during a time span of 7 days, the
					connection is switched to 100Mbit/s.</div>

				<div class="faq-question pt-4">3. Can I run a custom image on my Virtual Private Server?</div>
				<div class="faq-ans">Answer: Unfortunately we don't support this right now. You may want to select
					something from our wide Operating System offerings.</div>

				<div class="faq-question pt-4">4. I want to re-install my Operating System, how do I go about this?
				</div>
				<div class="faq-ans">Answer: Please write us an email at <a
						href="mailto:itan@kenmark.in">itan@kenmark.in</a> with the request and this will
					be done within 2 hours. Kindly take a backup before you send the request, because an OS re-install
					formats the entire SSD. We are not responsible for any data loss during an OS re-installation.</div>

				<div class="faq-question pt-4">5. Can I run a Mail Server on my Virtual Private Server?
				</div>
				<div class="faq-ans">Answer: We allow you to run private mail servers, but have very strict rules about
					spamming or bulk emails. If your server is reported for spamming, your server port for mail gets
					blocked instantly.
				</div>
				<div class="faq-question pt-4">6. Can I get a discount on my Virtual Private Server, if I buy this for a
					fixed term?
				</div>
				<div class="faq-ans">Answer: Yes, please speak to us about this and we can setup a deal for you. We do
					charge an upfront amount for this, but is cheaper in the long run. Minimum contracts for discounted
					rates start at 6 Months.
				</div>
				<div class="faq-question pt-4">7. Will Kenmark ITan Solutions take care of any Hardware Issues / Drive
					Crashes?
				</div>
				<div class="faq-ans">Answer: If you notice a problem on the server, please contact us and we can check
					any failures and move your VM to another host machine, if deemed necessary. </div>
				<div class="faq-question pt-4">8. Will Kenmark ITan Solutions be mirroring my Data to a Backup Server?
				</div>
				<div class="faq-ans">Answer: No! You are responsible to backup your data. We recommend our backup space
					which you can choose as an Add-on during your online order.
				</div>
				<div class="faq-question pt-4">9. What is the maximum storage you can provide?
				</div>
				<div class="faq-ans">Answer: The biggest SSD we provide is a 1.6GB SSD, this is the max we can provide
					for a particular VPS. You may want to buy additional backup storage to use as a Network Attached
					Storage.
				</div>
				<div class="faq-question pt-4">10. Can I upgrade only a certain component of my base system?
				</div>
				<div class="faq-ans">Answer: Unfortunately, we don't do that at the moment. If you'd like to upgrade
					your base system, we recommend doing so to the next available package. If you'd like a new VPS, then
					we can create a new one and you can move your data manually over. If you'd like us to upgrade it, we
					charge a one time fee of Rs. 2,000/-. If you're already half-way through your month, we don't charge
					you any extra for the upgraded specifications.
				</div>
				<div class="faq-question pt-4">4. I want to re-install my Operating System, how do I go about this?
				</div>
				<div class="faq-ans">Answer: Please write us an email at itan@kenmark.in with the request and this
					will
					be done within 2 hours. Kindly take a backup before you send the request,
				</div>
				<div class="faq-question pt-4">11. What is the guarantee that Kenmark ITan Solutions protects my Data
					against Hardware Failures?
				</div>
				<div class="faq-ans">Answer: Our hardware is always 'state of the art', but we cannot guarantee a 100%
					against hardware failures. We always recommend a backup.
				</div>
				<div class="faq-question pt-4">12. What happens if my server is down?
				</div>
				<div class="faq-ans">Answer: Report it! We will look into it ASAP. Any downtime would be compensated
					into your next month with the amount of credits mentioned above.
				</div>
				<div class="faq-question pt-4">13. Help! I've misplaced my password.
				</div>
				<div class="faq-ans">Answer: We create an account on all Windows Servers called KiTS. If this has been
					hampered with, we will not be able to recover your VPS. If we still have access to this, we can help
					you reset your Administrator Account. For Linux Servers, we recommend that you let alone the root
					password as what it was on creation and create another user with sudo rights.
				</div>
				<br><br>
			</div>
		</div>
	</div>
	<div class="row amount p-lg-4 justify-content-center p-3 p-md-4">
		<div class="text-center ml-lg-auto mr-lg-4 text-lg-right">
			<div class="option-cost text-center px-4">
				{{((pack.priceINR)+os+backup+cPanel+ip+ssl | currency:'INR') || '0'}}
				<a routerlink='/cart' (click)="toCart((pack.priceINR)+os+backup+cPanel+ip+ssl)"
					class="ken-btn p-2 p-lg-2 px-2 px-lg-4 mx-lg-2 text-white">
					Continue
				</a>
			</div>
		</div>
	</div>
</div>