<div class="container-fluid p-0">
    <mat-progress-bar *ngIf="showProgress" mode="determinate" value="{{progressBar}}"></mat-progress-bar>
    <div class="row m-0 text-center jumbo p-lg-5" id="landingPage">
        <div class="col p-5 customPadding">
            <p class="heading">Development</p>
            <p class="subHeading">
                We realize that every client has specific requirements hence, we tailor our development services
                & prices to you ensuring you only pay for what you need.
            </p>
            <p class="quoteText">
                Generate a quote in 5 steps
            </p>
            <input class="startHere" type="button" value="Start Here" (click)="getStarted('start')">
        </div>
    </div>

    <!-- fisrt form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" id="firstStep1" style="background-color: #eeeeee;">
        <div class="col-2">Back</div>
        <div class="col-8 text-center">
            <p class="stepText">Step 1</p>
        </div>
        <div class="col-2 text-right nextText" (click)="stepSecond('forward')">Next</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 1st -->
    <div class="row m-0 jumbo" id="firstStep">
        <div class="col-1 arrowClassBack">
            <img src="assets/images/icons/back.png" alt="Back">
        </div>
        <div class="col-10 text-center p-lg-5 customPadding2">
            <p class="devTypeText">Choose type of development:</p>
            <p class="subHeading">Please choose all that apply</p>
        </div>
        <div class="col-1 arrowClassNext" (click)="stepSecond('forward')">
            <img src="assets/images/icons/next.png" alt="Next">
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('erp')"
                        [ngClass]="devMenus.erp ? 'success' : 'danger'">ERP</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('eCommerce')"
                        [ngClass]="devMenus.eCommerce ? 'success' : 'danger'">e-Commerce</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('portfolio')"
                        [ngClass]="devMenus.portfolio ? 'success' : 'danger'">Portfolio</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('landingPage')"
                        [ngClass]="devMenus.landingPage ? 'success' : 'danger'">Landing Page</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('security')"
                        [ngClass]="devMenus.security ? 'success' : 'danger'">Security</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('socialMediaPlatform')"
                        [ngClass]="devMenus.socialMediaPlatform ? 'success' : 'danger'">Social Media Platform
                    </p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('accounting')"
                        [ngClass]="devMenus.accounting ? 'success' : 'danger'">Accounting</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getDevType('customBuild')"
                        [ngClass]="devMenus.customBuild ? 'success' : 'danger'">Custom Build</p>
                </div>
            </div>
        </div>
    </div>
    <!-- step 1st -->
    <!-- fisrt form -->
    <!------------------------------------------------------------------------------------------------------------------------------------->

    <!-- second form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" id="secondStep1" style="background-color: #eeeeee;">
        <div class="col-2 backText" (click)="getStarted('backward')">Back</div>
        <div class="col-8 text-center">
            <p class="stepText">Step 2</p>
        </div>
        <div class="col-2 text-right nextText" (click)="stepThird('forward')">Next</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 2nd -->
    <div class="row m-0 jumbo" id="secondStep">
        <div class="col-1 arrowClassBack" (click)="getStarted('backward')">
            <img src="assets/images/icons/back.png" alt="Back">
        </div>
        <div class="col-10 text-center p-lg-5 customPadding2">
            <p class="devTypeText">Select target devices:</p>
            <p class="subHeading">Please choose all that apply</p>
        </div>
        <div class="col-1 arrowClassNext" (click)="stepThird('forward')">
            <img src="assets/images/icons/next.png" alt="Back">
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getTargetDevice('mobile')"
                        [ngClass]="targetDeviceMobile.mobile ? 'success' : 'danger'">Mobile</p>
                </div>
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getTargetDevice('desktop')"
                        [ngClass]="targetDeviceDesktop.desktop ? 'success' : 'danger'">Desktop</p>
                </div>
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getTargetDevice('tablet')"
                        [ngClass]="targetDeviceTablet.tablet ? 'success' : 'danger'">Tablet</p>
                </div>
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText subPaddingClass" (click)="getTargetMobilePlatform('browser')"
                        [ngClass]="targetDeviceMobile.browser ? 'success' : 'danger'">Browser</p>
                    <p class="typeText subPaddingClass" (click)="getTargetMobilePlatform('android')"
                        [ngClass]="targetDeviceMobile.android ? 'success' : 'danger'">Android</p>
                    <p class="typeText subPaddingClass" (click)="getTargetMobilePlatform('ios')"
                        [ngClass]="targetDeviceMobile.ios ? 'success' : 'danger'">iOS</p>
                </div>
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getTargetDesktopPlatform('browser')"
                        [ngClass]="targetDeviceDesktop.browser ? 'success' : 'danger'">Browser</p>
                </div>
                <div class="col-lg-4 col-md-4 col-6 text-center p-0">
                    <p class="typeText subPaddingClass" (click)="getTargetTabletPlatform('browser')"
                        [ngClass]="targetDeviceTablet.browser ? 'success' : 'danger'">Browser</p>
                    <p class="typeText subPaddingClass" (click)="getTargetTabletPlatform('android')"
                        [ngClass]="targetDeviceTablet.android ? 'success' : 'danger'">Android</p>
                    <p class="typeText subPaddingClass" (click)="getTargetTabletPlatform('ios')"
                        [ngClass]="targetDeviceTablet.ios ? 'success' : 'danger'">iOS</p>
                </div>
            </div>
        </div>
    </div>
    <!-- step 2nd -->
    <!-- second form -->
    <!------------------------------------------------------------------------------------------------------------------------------------->

    <!-- third form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" id="thirdStep1" style="background-color: #eeeeee;">
        <div class="col-2 backText" (click)="stepSecond('backward')">Back</div>
        <div class="col-8 text-center">
            <p class="stepText">Step 3</p>
        </div>
        <div class="col-2 text-right nextText" (click)="stepFourth('forward')">Next</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 3rd -->
    <div class="row m-0 jumbo" id="thirdStep">
        <div class="col-1 arrowClassBack" (click)="stepSecond('backward')">
            <img src="assets/images/icons/back.png" alt="Back">
        </div>
        <div class="col-10 text-center  p-lg-5 customPadding2">
            <p class="devTypeText">Select elements & functions:</p>
            <p class="subHeading">Please choose all that apply</p>
        </div>
        <div class="col-1 arrowClassNext" (click)="stepFourth('forward')">
            <img src="assets/images/icons/next.png" alt="Back">
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('paymentGateway')"
                        [ngClass]="funcMenus.paymentGateway ? 'success' : 'danger'">Payment Gateway</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('smsGateway')"
                        [ngClass]="funcMenus.smsGateway ? 'success' : 'danger'">SMS Gateway</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('emailGateway')"
                        [ngClass]="funcMenus.emailGateway ? 'success' : 'danger'">Email Gateway</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('shippingGateway')"
                        [ngClass]="funcMenus.shippingGateway ? 'success' : 'danger'">Shipping Gateway</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('otpVerification')"
                        [ngClass]="funcMenus.otpVerification ? 'success' : 'danger'">OTP Verification</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('identityVerificationKYC')"
                        [ngClass]="funcMenus.identityVerificationKYC ? 'success' : 'danger'">Identity
                        Verification/KYC
                    </p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('googleMaps')"
                        [ngClass]="funcMenus.googleMaps ? 'success' : 'danger'">Google Maps</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('googleAdWords')"
                        [ngClass]="funcMenus.googleAdWords ? 'success' : 'danger'">Google AdWords</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('googleAnalytics')"
                        [ngClass]="funcMenus.googleAnalytics ? 'success' : 'danger'">Google Analytics</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('socialMediaIntegration')"
                        [ngClass]="funcMenus.socialMediaIntegration ? 'success' : 'danger'">Social Media
                        Integration</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('logInWithGoogle')"
                        [ngClass]="funcMenus.logInWithGoogle ? 'success' : 'danger'">Log in with Google</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('logInWithFacebook')"
                        [ngClass]="funcMenus.logInWithFacebook ? 'success' : 'danger'">Log in with Facebook</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('contactForm')"
                        [ngClass]="funcMenus.contactForm ? 'success' : 'danger'">Contact Form</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('blog')"
                        [ngClass]="funcMenus.blog ? 'success' : 'danger'">Blog</p>
                </div>
                <div class="col-lg-3 col-md-3 col-6 text-center p-0">
                    <p class="typeText paddingClass" (click)="getFuncType('mailingListSubscription')"
                        [ngClass]="funcMenus.mailingListSubscription ? 'success' : 'danger'">Mailing list
                        subscription
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- step 3rd -->
    <!-- third form -->
    <!------------------------------------------------------------------------------------------------------------------------------------->

    <!-- foruth form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" id="fourthStep1" style="background-color: #eeeeee;">
        <div class="col-2 backText" (click)="stepThird('backward')">Back</div>
        <div class="col-8 text-center">
            <p class="stepText">Step 4</p>
        </div>
        <div class="col-2 text-right nextText" (click)="submitReview('forward')">Next</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 4th -->
    <div class="row m-0 jumbo" id="fourthStep">
        <div class="col-1 arrowClassBack" (click)="stepThird('backward')">
            <img src="assets/images/icons/back.png" alt="Back">
        </div>
        <div class="col-10 text-center p-lg-5 customPadding3">
            <p class="devTypeText">Fill in your details:</p>
            <p class="subHeading">Tell us more about your requirements</p>
        </div>
        <div class="col-1 arrowClassNext" (click)="submitReview('forward')">
            <img src="assets/images/icons/next.png" alt="Back">
        </div>
        <div class="col-12" style="background-color: #eeeeee;">
            <form>
                <div class="row p-5">
                    <div class="col-lg-6 col-md-6 p-3">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Name" [(ngModel)]="name" name="name">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-3">
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Email" [(ngModel)]="email"
                                name="email">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-3">
                        <div class="form-group">
                            <input type="number" class="form-control" placeholder="Number" [(ngModel)]="mobile"
                                name="mobile">
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-3">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Company" [(ngModel)]="companyName"
                                name="companyName">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 p-3">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Message" [(ngModel)]="message"
                                name="message">
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- step 4th -->
    <!-- foruth form -->
    <!------------------------------------------------------------------------------------------------------------------------------------->

    <!-- review form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" id="review1" style="background-color: #eeeeee;">
        <div class="col-2 backText" (click)="stepFourth('backward')">Back</div>
        <div class="col-8 text-center">
            <p class="stepText">Step 5</p>
        </div>
        <div class="col-2 text-right finishText" (click)="thankYouPage('forward')">Finish</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 4th -->
    <div class="row m-0 jumbo" id="review">
        <div class="col-1 arrowClassBack" (click)="stepFourth('backward')">
            <img src="assets/images/icons/back.png" alt="Back">
        </div>
        <div class="col-10 text-center p-lg-5 customPadding2">
            <p class="devTypeText">Review & submit:</p>
            <p class="subHeading">Please review and edit if needed.</p>
        </div>
        <div class="col-1 arrowClassNext" (click)="thankYouPage('forward')">
            <img src="assets/images/icons/next.png" alt="Back">
        </div>
        <div class="col-12">
            <div class="row">
                <div class="col-lg-4 p-5 reviewAlignment">
                    <p class="stepText">Step 1</p>
                    <p class="stepType">Type of development</p>
                </div>
                <div class="col-lg-8" style="background-color: #f6f6f6;padding: 5rem;">
                    <img class="editIcon" src="assets/images/icons/Path 236.png" alt="Edit Icon" (click)="showThisStep('Step 1')">
                    <!-- <i class="fa fa-edit" (click)="showThisStep('Step 1')"></i> -->
                    <ul>
                        <li *ngFor="let i of devType" style="text-transform: capitalize;">{{i}}</li>
                    </ul>
                    <p class="bottomLine"></p>
                </div>

                <div class="col-lg-4 p-5 reviewAlignment">
                    <p class="stepText">Step 2</p>
                    <p class="stepType">Target Devices</p>
                </div>
                <div class="col-lg-8" style="background-color: #f6f6f6;padding: 5rem;">
                    <img class="editIcon" src="assets/images/icons/Path 236.png" alt="Edit Icon" (click)="showThisStep('Step 2')">
                    <!-- <i class="fa fa-edit" (click)="showThisStep('Step 2')"></i> -->
                    <ul>
                        <li *ngIf="targetDeviceMobile.mobile == true">Mobile - <span
                                *ngIf="targetDeviceMobile.browser ==true">Browser,</span><span
                                *ngIf="targetDeviceMobile.android ==true">Android,</span><span
                                *ngIf="targetDeviceMobile.ios ==true">ios</span></li>
                        <li *ngIf="targetDeviceDesktop.desktop == true">Desktop - <span
                                *ngIf="targetDeviceDesktop.browser ==true">Browser,</span></li>
                        <li *ngIf="targetDeviceTablet.tablet == true">Tablet - <span
                                *ngIf="targetDeviceTablet.browser ==true">Browser,</span><span
                                *ngIf="targetDeviceTablet.android ==true">Android,</span><span
                                *ngIf="targetDeviceTablet.ios ==true">ios</span></li>
                    </ul>
                    <p class="bottomLine"></p>
                </div>

                <div class="col-lg-4 p-5 reviewAlignment">
                    <p class="stepText">Step 3</p>
                    <p class="stepType">Elements & Functions</p>
                </div>
                <div class="col-lg-8" style="background-color: #f6f6f6;padding: 5rem;">
                    <img class="editIcon" src="assets/images/icons/Path 236.png" alt="Edit Icon" (click)="showThisStep('Step 3')">
                    <!-- <i class="fa fa-edit" (click)="showThisStep('Step 3')"></i> -->
                    <ul>
                        <li *ngFor="let i of funcType" style="text-transform: capitalize;">{{i}}</li>
                    </ul>
                    <p class="bottomLine"></p>
                </div>

                <div class="col-lg-4 p-5 reviewAlignment">
                    <p class="stepText">Step 4</p>
                    <p class="stepType">Details</p>
                </div>
                <div class="col-lg-8" style="background-color: #f6f6f6;padding: 5rem;">
                    <img class="editIcon" src="assets/images/icons/Path 236.png" alt="Edit Icon" (click)="showThisStep('Step 4')">
                    <!-- <i class="fa fa-edit" (click)="showThisStep('Step 4')"></i> -->
                    <ul>
                        <li>{{name}}</li>
                        <li>{{email}}</li>
                        <li>{{mobile}}</li>
                        <li>{{companyName}}</li>
                        <li>{{message}}</li>
                    </ul>
                    <!-- <p class="bottomLine"></p> -->
                </div>
            </div>
            <div class="row p-5" style="background: #e2e2e2;">
                <div class="col-lg-12 text-right">
                    <input type="button" value="Submit" class="startHere" style="padding: 7px 63px;"
                        (click)="thankYouPage('forward')">
                </div>
            </div>
        </div>
    </div>
    <!-- step 4th -->
    <!-- review form -->
    <!------------------------------------------------------------------------------------------------------------------------------------->

    <!-- review form -->
    <!-- nav for instruction -->
    <div class="row m-0 p-2" *ngIf="thankYou" style="background-color: #eeeeee;">
        <div class="col-12 text-center stepText">Submitted</div>
    </div>
    <!-- nav for instruction -->
    <!-- step 4th -->
    <div class="row m-0 text-center jumbo" *ngIf="thankYou">
        <div class="col">
            <div class="customLastPage">
                <p class="heading">Thank you for your inquiry</p>
                <p class="subHeading">
                    We aim to get back to you within 24 hours.
                </p>
            </div>
            <div class="customLastPage2" style="background: #eeeeee;">
                <p class="quoteText" style="font-size: 1.3rem;">
                    Generate another quote
                </p>
                <input style="padding: 4px 80px;" class="startHere" type="button" value="Start Here"
                    (click)="getStarted('end')">
            </div>
        </div>
    </div>
    <!-- step 4th -->
    <!-- review form -->

</div>