import { Component, OnInit } from '@angular/core';
import { NgForm, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginError: any;
  errorMessage:any;

  constructor(
    private route: Router,
    private authService: AuthService) { }

  email = new FormControl('', [Validators.required, Validators.email]);
  hide = true;

  ngOnInit() {
    //  this.authService.checkLogIn();
  }
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Please enter email';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }

  sendUser(form: NgForm) {
    if (form.invalid) {
      return;
    }
    let credentials = {
      password: form.value.password,
      email: form.value.email,
    }
    this.authService.login(credentials)
      .subscribe(result => {
        if (result) {
          this.route.navigate(['/orders']);
        }
      },
        error => {
          this.errorMessage = error
          this.loginError = true;
        }
      );
  }
}