<div class="container-fluid">
    <div class="row p-5 font-weight-lighter bg-light-img text-center">
        <div class="col">
            <p class="heading">Hello,</p>
            <p class="subheading">
                Based in Mumbai, Kenmark ITan (Information Technology Administration Network) Solutions is an ALL-IT-Solutions that aims to provide all IT services under one single roof. Be it establishing your online presence or building up an enterprise infrastructure, we cover it all. What’s the best part? We’re super competitive with our pricing while ensuring low maintenance costs for a long-term benefit. We love working with upcoming start-ups, small-medium businesses and even sole-proprietors. If you have an IT problem that needs solving, contact us today!
            </p>
        </div>
    </div>
    <!-- <div class="row">
        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 19.png" class="img-fluid" alt="web_img">
                <p class='pt-5 serviceName'>Web Hosting</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Web Hosting</p>
                <p class='text-white px-3 paraTwo'>Hosting the pride and joy of your buisness, 24 x 7 x 365.</p>
            </div>
        </div>

        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 20.png" class="img-fluid" alt="development_img">
                <p class="pt-5 serviceName">Development</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Development</p>
                <p class='text-white px-3 paraTwo'>Transform your imagination into an online reality.</p>
            </div>
        </div>

        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 18.png" class="img-fluid" alt="designing_img">
                <p class="pt-5 serviceName">Design</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Design</p>
                <p class='text-white px-3 paraTwo'>Be it UI/UX or graphics, we help bring your ideas to life</p>
            </div>
        </div>

        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 17.png" class="img-fluid" alt="branding_img">
                <p class='pt-5 serviceName'>Branding</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Branding</p>
                <p class='text-white px-3 paraTwo'>Branding solution tailored to your brand identity.</p>
            </div>
        </div>

        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 21.png" class="img-fluid" alt="marketing_img">
                <p class="pt-5 serviceName">Marketing</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Marketing</p>
                <p class='text-white px-3 paraTwo'>From SEO, SMM to everything offline, we provide integrated marketing
                    solutions
                    for your needs.</p>
            </div>
        </div>

        <div class="col-lg col-6 body text-center services">
            <div class="alwaysVisible">
                <img src="../../../assets/images/icons/Mask Group 22.png" class="img-fluid" alt="consultancy_img">
                <p class='pt-5 serviceName'>Consultancy</p>
            </div>
            <div class="onlyHover">
                <p class='px-3 paraOne'>Consultancy</p>
                <p class='text-white px-3 paraTwo'>Provide third-party guidance, feedback and technical advice</p>
            </div>
        </div>
    </div> -->
</div>