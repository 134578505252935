<div class="container-fluid text-center bg-light-img">
    <div class="section-head font-weight-lighter choosePack">Choose your VPS pack</div>
    <div *ngIf="posts" class="row packages">
        <ng-container *ngFor="let post of posts.product">
            <div class="col-lg col-sm-12 col-md-6 py-lg-5 py-2" style="background-color: #eeeeee;border: 1px solid whitesmoke;">
                <div class="package-cost px-2 mb-5">
                    <abbr class="costType">{{post.name}}</abbr>
                    <p class="p-lg-0 p-md-0 pricePerMon">Starting at {{post.priceINR | currency:'INR'}}/Mo</p>
                
                </div>
                <div class="py-4">
                    <p class="textColour">{{post.priceINR | currency:'INR'}}/Mo</p>
                    <p class="textColour">{{post.processor}}</p>
                    <p class="textColour">{{post.memory}}</p>
                    <p class="textColour">{{post.baseStorage}} SSD</p>
                    <p class="textColour">{{post.network}}</p>
                    <p class="textColour">{{post.bandwidth}}</p>
                    <a [routerLink]="['custom']" (click)="sendPack(post)" class="ken-btn p-2 text-white px-4 mt-5">
                        Configure
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>