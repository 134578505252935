import { LocalCacheService } from './local-cache.service';
import { Injectable, OnInit } from '@angular/core';
import { map, catchError } from "rxjs/operators";
import { throwError } from 'rxjs/internal/observable/throwError';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { stringify } from 'querystring';

@Injectable({
  providedIn: 'root'
})
export class HostingService {
  posts: any;
  id: number;
  data = false;
  url: string;
  constructor(private http: HttpClient) { }
  private pack = new BehaviorSubject('default message');
  currentPack = this.pack.asObservable();

  sendData(message: any) {
    this.pack.next(message);
    this.data = true;
  }
  getAll(id) {
    this.id = id;
    return this.http.get("https://kits.api-central.kenmarkserver.com/products/" + id)
      .pipe(map(res => this.posts = res),
        catchError(this.errorHandler));
  }

  errorHandler(error) {
    let errorMessage = '';
    if (error.code == 500) { errorMessage = 'Server is offline' }
    else if (error.code == 503) { errorMessage = "You're not connected to the internet" }
    else { errorMessage = error.error.message; }
    return throwError(errorMessage);
  }
}
