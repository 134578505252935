import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  paymentPortal = 1;
  quantity = 1;
  proDetails: any;
  constructor() { }
  ngOnInit(): void {
  }
  minus() {
    if (this.quantity == 1) {
      return;
    } else {
      --this.quantity;
      return;
    }
  }
  add() {
    ++this.quantity;
    return
  }
  showProDetails(){
    this.proDetails = this.proDetails ? false : true;
  }
}
