import { HostingService } from 'src/app/services/hosting.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import {Location} from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class CustomGuard implements CanActivate {
  constructor(private service: HostingService, private route: Router,private _location:Location) { }
  type:string;//from hosting service to change uurl route dynamically
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.service.data == true) {
      return true;
    }
    if (this.service.data == false) {
      this._location.back();
      // this.route.navigateByUrl('/hosting/' + this.sharing.id);
      return false;
    }
  }
}
