import { Component } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactFormService } from 'src/app/services/contact-form.service';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent{

  hasError() { }
  onCountryChange() { }
  telInputObject() { }
  getNumber() { }
  constructor(private service: ContactFormService, private _snackBar: MatSnackBar) { }
  subject = 'Project';
  email = new FormControl('', [Validators.required, Validators.email]);
  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'Please enter email';
    }
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
  post: any;
  errorMessage = '';
  error = false;
  message = '';
  sendForm(form: NgForm, subject = this.subject) {
    if (form.invalid) {
      return;
    }
    let data = {
      name: form.value.name,
      email: form.value.email,
      number: form.value.number,
      company: form.value.company,
      subject: subject,
      message: form.value.message,
    }
    this.post = this.service.postAll(data)
      .subscribe(res => {
        console.log(res);
        this.message = "Contact From submitted!";
        this._snackBar.open(this.message, 'Dismiss', {
          duration: 2500,
        });
        form.resetForm()
      },
        (error) => {
          this.errorMessage = error;
          this.error = true;
        }
      );
    this.error = false;
  }

}
