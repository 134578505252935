<main>
<form class="d-flex justify-content-center" #loginForm="ngForm" (ngSubmit)="sendUser(loginForm)">
  <div class="div-warpper text-center">
    <h3 class="pb-2">Login</h3>
    <!-- <h3>Your account is a step away</h3> -->
    <!-- <label class="loginWithFacebook">
        <img src="assets/images/icons/facebook.png" alt="facebook">
        <span>&nbsp;Continue with Facebook</span>
    </label>
    <br>
    <label class="loginWithGoogle">
      <img src="assets/images/icons/google-hangouts.png" alt="google">
      <span>&nbsp;Continue with Google</span>
  </label> -->
  <!-- <br>
  <span>or</span>
  <br> -->
    <mat-error *ngIf='loginError' class='p-2'>{{errorMessage}}</mat-error>
    <mat-form-field appearance="outline" style="width: 244px;margin-top: 1rem;">
      <mat-label>Enter your email</mat-label>
      <input matInput ngModel name="email" #email="ngModel" email required>
      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
    <br>
    <mat-form-field appearance="outline">
      <mat-label>Enter your password</mat-label>
      <input matInput inputmode="email" name="password" minlength=3 ngModel #password="ngModel"
        [type]="hide ? 'password' : 'text'" required>
      <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide">
        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <br>
    <button mat-raised-button [disabled]="loginForm.invalid" color="accent" class="px-5 p-2">Login</button>
    <br>
    <br>
    <br>
    <mat-hint class="p-3">Don't have a account?</mat-hint>
  <div>
    <button mat-raised-button color="primary" routerLink="/signUp" class="mt-2 px-5 p-2">Sign Up</button>
  </div>
  </div>
</form>
</main>