<div class="container-fluid">
    <div class="row text-center p-lg-5" style="background-image: url('../../../assets/images/Background.png');">
      <div class="col p-lg-5">
        <p class="heading">Contact us</p>
      </div>
    </div>
    <div class="row py-lg-5 p-sm-4" style="background-color: #eeeeee;">
      <div class="col px-lg-3">
        <form #postForm="ngForm" (ngSubmit)="sendForm(postForm)">
          <div class="form-body container pl-lg-5">
            <div class="row">
              <div class="col-lg-6">
                <mat-form-field>
                  <mat-label>Name</mat-label>
                  <input matInput ngModel name="name" #name="ngModel" required>
                  <mat-error>{{hasError()}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field>
                  <mat-label>Enter your email</mat-label>
                  <input matInput ngModel name="email" #email="ngModel" email required>
                  <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field floatLabel="always">
                  <mat-label>Number</mat-label>
                  <input ng2TelInput matInput ngModel name="number" #number=ngModel required>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field>
                  <mat-label>Company</mat-label>
                  <input matInput ngModel name="company" company="ngModel" required>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field appearance="legacy">
                  <mat-label>Subject</mat-label>
                  <mat-select [(value)]="subject">
                    <mat-option value="option2">Option 1</mat-option>
                    <mat-option value="Project">Project</mat-option>
                    <mat-option value="option3">Option 3</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-6">
                <mat-form-field class="full-width">
                  <mat-label>Message</mat-label>
                  <textarea ngModel name="message" message="ngModel" matInput maxlength="200"
                    placeholder="You are awesome!" required></textarea>
                </mat-form-field>
                <mat-error *ngIf="error">{{errorMessage}}</mat-error>
              </div>
              <div class="col-lg-12 pt-5 text-center">
                <button mat-raised-button [disabled]="postForm.invalid">Send Message</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>