import { Component, OnInit } from '@angular/core';
import { HostingCustomService } from 'src/app/services/hosting-custom.service';
import { HostingService } from 'src/app/services/hosting.service';

@Component({
  selector: 'app-web-hosting-custom',
  templateUrl: './web-hosting-custom.component.html',
  styleUrls: ['./web-hosting-custom.component.scss']
})
export class WebHostingCustomComponent implements OnInit {
  posts: any;
  appId: number = 81;
  cost: number = 0;
  pack: any;
  constructor(private service: HostingCustomService, private sharing: HostingService) { }

  ngOnInit() {
    this.service.getAll('3').subscribe(post => this.posts = post);
    this.sharing.currentPack.subscribe(message => this.pack = message);
  }
  appCost(id, cost) {
    this.appId = id;
    this.cost = cost;
  }
}
