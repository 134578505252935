<main>
  <!-- <h2 class="p-5 text-center">Sign up with Kenmark</h2> -->
  <form class="d-flex justify-content-center" #postForm="ngForm" (ngSubmit)="sendForm(postForm)">
    <div class="div-warpper text-center">
      <h2 class="ext-center">Sign up with Kenmark</h2>
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput ngModel name="fname" #fname="ngModel" required>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput ngModel name="lname" #lname="ngModel" required>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Enter your email</mat-label>
        <input inputmode="email" matInput name="email" [formControl]="email" required>
        <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Company</mat-label>
        <input matInput ngModel name="company" company="ngModel">
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Number</mat-label>
        <input matInput type="number" ngModel name="number" #number=ngModel placeholder="8446610100" required>
      </mat-form-field>
      <br>
      <br>
      <button mat-raised-button [disabled]="postForm.invalid">Send Message</button><br><br>
    </div>
  </form>
</main>