import { DataService } from './data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ContactFormService extends DataService {
  constructor(http: HttpClient) {
    super('https://kits.api-central.kenmarkserver.com/contact/form',http);
  }
}
