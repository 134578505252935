import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2TelInputModule } from 'ng2-tel-input';

import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './components/login/login.component';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { NavComponent } from './components/nav/nav.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignupComponent } from './components/signup/signup.component';
import { OrdersComponent } from './components/orders/orders.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { CartComponent } from './components/cart/cart.component';

import { VpsComponent } from './components/hosting/vps/vps.component';
import { DedicatedComponent } from './components/hosting/dedicated/dedicated.component';
import { CloudStorageComponent } from './components/hosting/cloud-storage/cloud-storage.component';

import { VpsCustomComponent } from './components/hostingCustom/vps-custom/vps-custom.component';
import { CloudCustomComponent } from './components/hostingCustom/cloud-custom/cloud-custom.component';
import { DedicatedCustomComponent } from './components/hostingCustom/dedicated-custom/dedicated-custom.component';
import { WebHostingComponent } from './components/hosting/web-hosting/web-hosting.component';
import { GameServersComponent } from './components/hosting/game-servers/game-servers.component';
import { WebHostingCustomComponent } from './components/hostingCustom/web-hosting-custom/web-hosting-custom.component';
import { ContactDetailsComponent } from './components/contact/contact-details/contact-details.component';
import { ContactFormComponent } from './components/contact/contact-form/contact-form.component';
import { OurWorkComponent } from './components/company/our-work/our-work.component';
import { OurTeamComponent } from './components/company/our-team/our-team.component';
import { AboutComponent } from './components/company/about/about.component';
import { HostingComponent } from './components/hosting/hosting/hosting.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { DevelopmentComponent } from './components/development/development.component';

import { AngularFullpageModule } from '@fullpage/angular-fullpage';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FooterComponent,
    LoginComponent,
    VpsCustomComponent,
    CloudCustomComponent,
    VpsComponent,
    DedicatedCustomComponent,
    OrdersComponent,
    SignupComponent,
    DedicatedComponent,
    CloudStorageComponent,
    WebHostingComponent,
    GameServersComponent,
    WebHostingCustomComponent,
    CheckoutComponent,
    CartComponent,
    ContactDetailsComponent,
    ContactFormComponent,
    OurWorkComponent,
    OurTeamComponent,
    AboutComponent,
    HostingComponent,
    PoliciesComponent,
    DevelopmentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatSelectModule,
    MatExpansionModule,
    MatRadioModule,
    Ng2TelInputModule,
    MatTabsModule,
    MatProgressBarModule,
    MatCheckboxModule,
    AngularFullpageModule
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {
}

