import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';
import { LocalCacheService } from "./local-cache.service";
@Injectable({
  providedIn: 'root'
})
export class AuthService extends DataService {
  constructor(http: HttpClient, private service: LocalCacheService) {
    super('https://kits.api-central.kenmarkserver.com/user/login', http);
  }
  isLoggedIn: boolean = false;

  login(credentials) {
    return this.postAll(credentials)
      .pipe(map(response => {
        console.log(response)
        let result = response;
        if (result && result.token) {
          this.isLoggedIn = true
          this.service.setItem('token', result.token);
          return this.isLoggedIn;
        }
        else {
          this.isLoggedIn = false
          return this.isLoggedIn;
        }
      }));
  }
  SignUp() { }
  logout() { }
  checkLogIn() {
    let cacheToken;
    cacheToken = this.service.getItem('token')
    if (cacheToken.__zone_symbol__state) {
      this.isLoggedIn = true;
      console.log("hey ",this.isLoggedIn + cacheToken.__zone_symbol__state)
      return this.isLoggedIn;
    }
    else {
      this.isLoggedIn = false
      return this.isLoggedIn;
    }
  }
}